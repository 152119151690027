import React from 'react';
import IpSidebar from './sidebar/ipSidebar.jsx';
import QuickList from '../mainPanel/quickList.jsx';

class SidePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showIpList: true };
    }

    render() {
        const { isNarrow, list, view, isMobile, sidePanelStatus } = this.props;
        const { showIpList } = this.state;
        const isNarrowAndEmpty = isNarrow && list.length == 0;
        const quickListLogic = showIpList && view === 'detail' && !isMobile && !isNarrowAndEmpty;
        const isHidden = sidePanelStatus ? '' : 'hidden';
        return (
            <div className={`side-panel ${isHidden}`}>
                <div className="tab-slider--nav">
                    <ul className="tab-slider--tabs">
                        <li
                            className={'tab-slider--trigger ' + (showIpList ? 'active' : '')}
                            onClick={() => this.setState({ showIpList: true })}
                        >
                            IPs
                        </li>
                        <li
                            className={'tab-slider--trigger ' + (showIpList ? '' : 'active')}
                            onClick={() => this.setState({ showIpList: false })}
                        >
                            Networks
                        </li>
                    </ul>
                </div>
                { quickListLogic
                    &&
                        <QuickList
                            list={this.props.list}
                            addValToQuickList={this.props.addValToQuickList}
                            handleDetailSwitch={this.props.handleDetailSwitch}
                            deleteQuickList={this.props.deleteQuickList}
                        />
                }
                <IpSidebar
                    isHidden={this.props.view != 'detail'}
                    colorList={this.props.colorList}
                    addValToQuickList={this.props.addValToQuickList}
                    slaveList={this.props.slaveList}
                    showIpList={this.state.showIpList}
                    {...this.props}
                />
            </div>
        );
    }
}

export default SidePanel;
