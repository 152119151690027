/* global $:true */

import React from 'react';
import Utils from '../../../js/utils.js';
import Category from './category.jsx';
import IP from './ip.jsx';
import '../../../js/chart';
import CategorySettingsModal from '../../modals/categorySettings.modal.jsx';
import SearchForAS from '../searchAS.jsx';

/**
 * PROPS
 *
 * setCategoriesGlobally - [func] -
 * addValToQuickList - [func] - adds values in quicklist in sidepanel
 * reloadConfig - [func] - reloads the main config and updates page
 * isRoot - [bool] - user privileges
 * updateSelectedIp - [func] - changes the ip that is in the view
 * config - [obj] - this.is the main config /config api request
 * colorList - [arr]
 * isHidden - [bool] - is side panel hidden
 * slaveList - [obj] - {'AMS':'1.1.1.1', 'LON': '2.2.2.2', ...}
 * autoGroupKeys - [arr] - array of strings, category names
 */

class IpSidebar extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.sortIps = this.sortIps.bind(this);
        this.getIPcolor = this.getIPcolor.bind(this);
        this.createComponentStructure = this.createComponentStructure.bind(this);
        this.createSidePanelContent = this.createSidePanelContent.bind(this);
        this.createIPList = this.createIPList.bind(this);
        this.createIpLossList = this.createIpLossList.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.getIPs = this.getIPs.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = {
            data: {},
            openCategories: [],
            isOpen: true,
            showIPs: true,
            filtered: null,
            sortedGroupKeys: [],
            sortedCatKeys: []
        };
    }

    sortIps(props) {
        const data = Utils.getPropertyOfObject(['config', 'ping', 'ips'], props, {});
        const list = { I_P_S: [], FAVOURITES: { I_P_S: [] } };
        for (const ip in data) {
            if(Utils.isSet(ip)){
                const { cat: categories, desc: title, fav: isfav } = data[ip];
                const ipObj = {
                    ip,
                    title: title == undefined ? data[ip] : title,
                    isfav: isfav == undefined ? false : isfav,
                };
                const category = Utils.getPropertyOfObject([0], categories, ""); // changed -> IP can only belong to one category
                let catLen = Utils.getPropertyOfObject([0, 'length'], categories, 0);
                if (catLen > 0) {
                    let categoryArray = category.split('->');
                    categoryArray = categoryArray.slice(0, categoryArray.length - 1);
                    this.assignIP(categoryArray, list, ipObj);
                } else {
                    list.I_P_S.push(ipObj);
                }
                if (isfav) {
                    list.FAVOURITES.I_P_S.push(ipObj);
                }
            }
        }
        const tree = this.createComponentStructure(list);
        const sorted = this.sortCategoryKeys(list, props);
        this.setState({
            data: list,
            structure: tree,
            sortedCatKeys: sorted.sortedCatKeys,
            sortedGroupKeys: sorted.sortedGroupKeys,
        });
        sorted.sortedGroupKeys.forEach(key => delete list[key]);
        this.props.setCategoriesGlobally(list);
    }

    assignIP(categories, list, ipObj) {
        for (const i in categories) {
            const cat = categories[i];
            if (list[cat] == undefined) {
                list[cat] = {};
            }
            list = list[cat];
        }
        if (list.I_P_S == undefined) {
            list.I_P_S = [];
        }
        list.I_P_S.push(ipObj);
    }

    getIPcolor(ip) {
        let color = this.colorList[ip];
        color = color != undefined ? color.AVERAGE : color;
        return Utils.getLossColor(color);
    }

    createComponentStructure(list) {
        const structure = JSON.parse(JSON.stringify(list)); // deep copy
        return this.createSidePanelContent(list, structure, []);
    }

    createSidePanelContent(original, list, path) {
        if (Array.isArray(list)) {
            return this.createIPList(list);
        }
        const tree = {};
        const catArr = {};
        for (const key in list) {
            const newPath = JSON.parse(JSON.stringify(path));
            newPath.push(key);
            const branch = this.createSidePanelContent(original[key], list[key], newPath);
            if (key != 'I_P_S') {
                catArr[key] = (
                    <Category
                        key={key}
                        name={key}
                        size={this.getListSize(original[key])}
                        data={original[key]}
                        addValToQuickList={this.props.addValToQuickList}
                        ipList={branch.I_P_S}
                        subContent={branch}
                        lossList={this.createIpLossList(original[key])}
                        path={newPath}
                        reloadConfig={this.props.reloadConfig}
                        showSettings={this.props.isRoot}
                        slaveList={this.props.slaveList}
                    />
                );
            }
            tree[key] = branch;
            tree.C_A_T = catArr;
        }
        return tree;
    }

    createIPList(data) {
        const ipList = {};
        for (const i in data) {
            const item = data[i];
            // this is later used for sorting keys in the sidebar, so using IPs as keys is not a solution
            // since IPS are listed under aliase
            let uniqueKeys = Utils.isSet(item.title) ? item.title : item.ip;
            // place IPS under aliases for sorting purposes, if alias is not set use IP.
            // sometimes different ips can have same alias, check that and push only unique keys
            if (uniqueKeys in ipList) {
                uniqueKeys = `${item.title} / ${item.ip}`;
            }
            ipList[uniqueKeys] = (
                <IP
                    // info
                    key={item.ip}
                    ip={item.ip}
                    title={item.title}
                    color={this.getIPcolor(item.ip)}
                    // actions
                    updateSelectedIp={this.props.updateSelectedIp}
                    addValToQuickList={this.props.addValToQuickList}
                />
            );
        }
        return ipList;
    }

    createIpLossList(list) {
        list = list.I_P_S;
        const ipLossList = [];
        for (const i in list) {
            const { ip } = list[i];
            let loss = this.colorList[ip];
            loss = Utils.isSet(loss) ? loss.AVERAGE : 0;
            ipLossList.push({ ip, loss });
        }
        return ipLossList;
    }

    getListSize(data) {
        let size = 0;
        if (Utils.isSet(data)) {
            const keys = Object.keys(data);
            size = keys.length;
            if (keys.includes('I_P_S')) {
                size -= 1;
                size += data.I_P_S.length;
            }
        }
        return size;
    }

    sortCategoryKeys(list, props) {
        const sortedGroupKeys = props.autoGroupKeys;
        const allKeys = Utils.sortKeys(list);
        const sortedCatKeys = allKeys.filter(key => !sortedGroupKeys.includes(key)); // filter out group-key
        const itemsToRemove = ['I_P_S', 'FAVOURITES'];
        itemsToRemove.forEach((key) => {
            const index = sortedCatKeys.indexOf(key);
            if (index !== -1) sortedCatKeys.splice(index, 1);
        });
        sortedCatKeys.push('I_P_S');
        sortedCatKeys.unshift('FAVOURITES');
        return { sortedCatKeys, sortedGroupKeys };
    }

    getCategories(data) {
        let resort;
        if (this.props.showIpList) {
            const keys = this.state.sortedCatKeys;
            resort = Utils.reSortValuesByKey(data, keys);
            if (keys[0] === 'FAVOURITES' && resort[0].props.size == 0) { // remove FAVOURITES if empty
                delete resort[0];
            }
        } else {
            const keys = this.state.sortedGroupKeys;
            const filtered = this.state.filtered;
            if(filtered){
                resort = Utils.reSortValuesByKey(data, filtered);
                if(filtered.length === 0){
                    return (<li key="no-data">no results</li>);
                };
            } else {
                resort = Utils.reSortValuesByKey(data, keys);
            }
        }
        return resort;
    }

    getIPs(ipList) {
        if (!this.props.showIpList) { return []; }
        const keys = Object.keys(ipList);
        if (keys.length > 0) {
            return (
                <Category
                    key='UNASSIGNED'
                    name='UNASSIGNED'
                    showSettings={false}
                    size={keys.length}
                    addValToQuickList={this.props.addValToQuickList}
                    ipList={ipList}
                    subContent={{ C_A_T: {} }}
                    reloadConfig={this.props.reloadConfig}
                />
            );
        }
    }

    updateCategories = (props) => {
        const list = this.state.data;
        const sorted = this.sortCategoryKeys(list, props);
        this.setState({
            sortedCatKeys: sorted.sortedCatKeys,
            sortedGroupKeys: sorted.sortedGroupKeys,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state != nextState || this.props.showIpList != nextProps.showIpList;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.config != nextProps.config || this.props.colorList != nextProps.colorList) {
            this.colorList = nextProps.colorList;
            this.sortIps(nextProps);
        }
    }

    componentWillMount() {
        if (this.props.view == 'detail' && Utils.isSet(this.props.config)) {
            this.colorList = this.props.colorList;
            this.sortIps(this.props);
        }
    }

    render() {
        if (this.props.isHidden) { return null; }
        return (
            <div id='sidebar' className={this.props.isHidden ? 'hidden' : ''}>
                { !this.props.showIpList &&
                    <div className={'topControl'}>
                        <SearchForAS
                            data={this.state.sortedGroupKeys}
                            setFiltered={(data) => this.setState({filtered: data})}/>
                        <CategorySettingsModal
                            showAutoGroupSettings={true}
                            slaveList={this.props.slaveList}
                            reloadConfig={this.props.reloadConfig}
                            isNewCategory={true}
                        />
                    </div>
                }
                <ul className='top-ul'>
                    {this.getCategories(this.state.structure.C_A_T)}
                    {this.getIPs(this.state.structure.I_P_S)}
                </ul>
            </div>
        );
    }
}

export default IpSidebar;
