import React from 'react';
import Search from './Search.jsx';
import AddIpModal from '../modals/addIP.modal.jsx';

/**
 * PROPS
 *
 * -- Search PROPS -- [6]
 * config [obj] - complicated structure to show preview
 * updateSelectedIp [func] - changes selected IP
 * isMobile [bool] - determines if the device is mobile
 * colorList [obj] -{1.1.1.11: {AMS: 0.26, AVERAGE: 0.14, NYC: 0.03, SUM: 0.28},...} - PL colors for all probes and AVG
 * theme [obj] - {label: "dark", colorBckgr: "#353A4E", colorText: "#474C60", ...} - colors for JS triggering
 * addValToQuickList [func] - adds ips into quicklist (quick overview in sidepanel for fast switching and comparing)
 *
 * -- AddIpModal - PROPS -- [3]
 * reloadConfig [func] - fetch and reload config
 * slaveList [obj] - {'AMS':'1.1.1.1', 'LON': '2.2.2.2', ...}
 * categories [obj] - {'CAT1':{'CAT1_SUB':{I_P_S[...]},I_P_S:[{ip: "1.1.1.1", title: "one", isfav: false},...]}
 * user [str] - 'user'/'root'
 */

const DetailPanel = props => (
    <div className='right detail-panel'>
        <Search
            config={props.config}
            updateSelectedIp={props.updateSelectedIp}
            isMobile={props.isMobile}
            colorList={props.colorList}
            theme={props.theme}
            addValToQuickList={props.addValToQuickList}
        />
        {props.isRoot &&
        <AddIpModal
            reloadConfig={props.reloadConfig}
            slaveList={props.slaveList}
            categories={props.categories}
        />
        }
    </div>
);

export default DetailPanel;
