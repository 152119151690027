import React from 'react';
import Utils from '../../js/utils.js';
import SettingsCategories from '../settings/settings.categories.jsx';
import SettingsVersion from '../settings/settings.version.jsx';
import SettingsPassword from '../settings/settings.password.jsx'
import SettingsGlobal from '../settings/settings.global.jsx';
import SettingsUsers from '../settings/settings.users.jsx';
import SettingsBilling from '../settings/settings.billing.jsx';
import SettingsAlerts from '../settings/alerts/settings.alerts.jsx';
import SettingsStatus from '../settings/settings.status.jsx';
import TopPanel from '../topPanel/topPanel.jsx';

class MainPanelSettings extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.loadData = this.loadData.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.getContent = this.getContent.bind(this);
        this.setUrl = this.setUrl.bind(this);
        this.setSettings = this.setSettings.bind(this);
        this.getSidePanel = this.getSidePanel.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = { data: {}, activeTab: 'PROBES / STATUS' };
    }

    loadData() {
    // ----------------- API CALL GET -----------------
        const successCallback = (data) => { this.setState({ data }); };
        const data = Utils.formApiData('/settings', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    changeTab(activeTab) {
        this.setState({ activeTab });
    }

    getContent() {
        const view = this.state.activeTab;
        switch (view) {
        case 'GLOBAL SETTINGS':
            return (
                <SettingsGlobal
                    data={this.state.data}
                    setUrl={this.setUrl}
                    name={view}
                    user={this.props.user}
                />
            );
        case 'CATEGORIES SETUP':
            return (
                <SettingsCategories
                    categories={this.props.categories}
                    selectedIP={this.props.selectedIP}
                    data={this.state.data}
                    saveCategories={this.setSettings}
                    name={view}
                />
            );
        case 'PASSWORD':
            return (
                <SettingsPassword
                    data={this.state.data}
                    name={view}
                    username={this.props.username}
                />
            );
        case 'USERS':
            return (
                <SettingsUsers
                        data={this.state.data}
                        name={view}
                        username={this.props.username}
                        isRoot={this.props.isRoot}
                />
            );
        case 'ALERTS':
            return (<SettingsAlerts data={this.state.data} name={view} isRoot={this.props.isRoot} />);
        case 'BILLING':
            return (<SettingsBilling data={this.state.data} name={view} />);
        case 'PROBES / STATUS':
            return (<SettingsStatus setHash={false} name={view} isRoot={this.props.isRoot} {...this.props} />);
        case 'ABOUT':
            return (
                <SettingsVersion
                    name={view}
                    user={this.props.user}
                    config={this.props.config.ping.ips}
                    isRoot={this.props.isRoot}
                />
            );
        }
    }

    setUrl(url, callbacks) {
        const data = JSON.parse(JSON.stringify(this.state.data));
        data.public_url = url;
        this.setState({ data });
        this.setSettings(data, callbacks);
    }

    setSettings(calldata, callbacks) {
    // ----------------- API CALL PUT -----------------
        const data = Utils.formApiData('/settings', true, 'json', callbacks.successCallback, callbacks.errorCallback);
        data.contentType = 'application/json';
        data.data = calldata;
        Utils.performPUTcall(data);
    }

    getSidePanel() {
        const tabs = [];
        const names = [
            'PROBES / STATUS',
            'GLOBAL SETTINGS',
            'ALERTS',
            'PASSWORD',
            'CATEGORIES SETUP',
            'USERS',
            'BILLING',
            'ABOUT'
        ];
        const isRoot = this.props.isRoot;
        const isVisible = [true, isRoot, isRoot, true, false, isRoot, false, true];
        const logos = ['cog', 'circle-o', 'bell', 'key', 'list-ul', 'user-plus', 'file-text-o', 'info-circle'];
        names.forEach((key, index) => {
            if (isVisible[index]) {
                tabs.push(
                    <li key={index} className={this.state.activeTab === key ? 'active' : ''}>
                        <a onClick={() => this.changeTab(key)}>
                            <i className={`fa fa-${logos[index]}`} />
                            {key}
                        </a>
                    </li>,
                );
            }
        })
        return (
            <div className='side-panel'>
                <ul className='options'>
                    {tabs}
                </ul>
            </div>
        );
    }

    componentWillMount() {
        Utils.setHashSilently('settings');
        this.loadData();
    }

    render() {
        return (
            <div id='mother' className={this.props.isMobile ? 'mobile' : ''}>
                <TopPanel
                    changeTab={this.changeTab}
                    {...this.props}
                    view='settings'
                />
                <div className='main-panel settings side-relative'>
                    { this.getSidePanel() }
                    { this.getContent() }
                </div>
            </div>
        );
    }
}

export default MainPanelSettings;
