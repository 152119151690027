/* global $:true */
import React from 'react';
import Clipboard from 'clipboard';
import SettingsIPModal from '../../modals/settingsIP.modal.jsx';
import SettingsBox from '../../common/settingsBox.jsx';
import { withTooltip } from 'react-tippy';


class ControlPanel extends React.Component {

    copyIPAction = (e) => {
        const scope = this;
        const selector = $(this.refs['ip-title']);
        const color = selector.css('color');
        selector.css('color', '#ADC852');
        selector.text('COPIED');
        setTimeout(() => {
            selector.text(scope.props.ipName);
            selector.css('color', color);
        }, 2000);
    }

    copyASAction = (e) => {
        const scope = this;
        const selector = $(this.refs['as-title']).find('.val');
        const color = selector.css('color');
        selector.css('color', '#ADC852');
        selector.text('COPIED');
        setTimeout(() => {
            selector.text(scope.props.as);
            selector.css('color', color);
        }, 2000);
    }

    componentDidUpdate() {
        const clipboard = new Clipboard('.copy-btn');
    }

    render() {
        const InfoIcon = () => (<i className='fa fa-cog cog_disabled' />);
        const tooltipOptions = {
            style: { marginLeft: '0px' },
            theme: 'dark',
            position: 'right',
            animation: 'perspective',
        };
        tooltipOptions.title = `This IP can not be individually modified beacuse it is a part of an autogroup. 
        To change settings you must edit the whole autogroup in the side panel under Networks section. 
        Settings button is represented by the cog icon located next to the autogroup's name`;
        const Tooltip = withTooltip(InfoIcon, tooltipOptions);
        const parentCategory = this.props.catList[0];
        const isNotAutoGroup = !this.props.autoGroupKeys.includes(parentCategory);
        return (
            <div className="control-panel-wrap safari-flex-fix">
                <div className="control-panel">
                    <div className='title-wrap'>
                        <a onClick={this.copyIPAction}>
                            <span
                                ref='ip-title'
                                data-clipboard-text={this.props.selectedIP}
                                className={`ip ip-info copy-btn ${this.props.ipName == 'no-title' ? 'no-title' : ''}`}
                            >
                                {this.props.ipName == 'no-title' ? this.props.selectedIP : this.props.ipName}
                            </span>
                        </a>
                        <a onClick={this.copyASAction}>
                            <span ref='as-title' className='as-info copy-btn' data-clipboard-text={this.props.as}>
                                AS:
                                <span className='val'>{ this.props.as }</span>
                                {!isNotAutoGroup &&
                                    <>
                                        <span className={'autogroup'}>{'AUTOGROUP'}</span>
                                        <span className={'autogroup'}>{parentCategory}</span>
                                    </>
                                }
                            </span>
                        </a>
                    </div>
                    {this.props.isRoot && isNotAutoGroup &&
                    <SettingsIPModal
                        alias={this.props.alias}
                        reloadConfig={this.props.reloadConfig}
                        slaveList={this.props.slaveList}
                        probesList={this.props.probesList}
                        selectedIP={this.props.selectedIP}
                        catList={this.props.catList}
                        config={this.props.config}
                        setStateConfig={this.props.setStateConfig}
                        probesListReport={this.props.probesListReport}
                        categories={this.props.categories}
                        autoGroupKeys={this.props.autoGroupKeys}
                    />
                    }
                    {this.props.isRoot && !isNotAutoGroup && <Tooltip />}
                </div>

                <SettingsBox theme={this.props.theme} changeColor={this.props.changeColor} />
            </div>
        );
    }
}

export default ControlPanel;
