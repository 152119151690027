import React from 'react';
import InnerHeader from './innerHeader.jsx';
import InnerHeaderMobile from './innerHeaderMobile.jsx';

const InnerWrap = (props) => {
    if (props.showName) {
        return (<div className='probe-title-wrap'><span>{props.ipName}</span></div>);
    } else {
        if(props.isMobile){
            return (<InnerHeaderMobile {...props}/>)
        }
        return (<InnerHeader {...props}/>)
    }
}

export default InnerWrap;