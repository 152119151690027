/* global DataBasic:true, DataAdvanced:true */
import React from 'react';
import Utils from '../../js/utils.js';
import Graph from './graph.jsx';
import HeaderWrap from './header/head/headerWrap.jsx';
import InnerWrap from './header/inner/innerWrap.jsx';
import GraphFooter from './footer/graphFooter.jsx';
import TraceLegend from './footer/traceLegend.jsx';
import '../../js/dataBasic';
import '../../js/dataAdvanced';

class GraphContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chart: null,
            graphData: null,
            yAxisLimit: null,
            stats: {},
            isUTC: false,
            zoomLevel: 1,
            customDebugAgrCount: 0,
        };
    }

    setChartInstance = (key, data) => this.setState({ [key]: data });

    setUTC = (isUTC) => this.setState({ isUTC });

    loadGraph = (data, ip, now, graphType) => {
        if (graphType == undefined) { graphType = this.props.ipDetails.graphType; }
        if (now == undefined) { now = Utils.getTimeNow(); }
        if (graphType === 'basic') {
            this.loadBasicGraph(data, ip, now);
        } else {
            this.loadAdvancedGraph(data, ip, now);
        }
    }

    switchYAxis = () => {
        const { chart } = this.state;
        const temp = chart.yAxis[0].max;
        const max = this.state.yAxisLimit;
        chart.yAxis[0].update({ max });
        this.setState({ yAxisLimit: temp });
    }

    getAgrCount = (epoch, type) => {
        const level = this.props.ipDetails.accuracy === 'normal' ? 0 : 1; // [low - 0, high - 1]
        const px = Utils.getChartLineWidth(this.props.isMobile, this.props.isNarrow);
        const isBasic = (type === 'basic');
        // -----------  calculate aggregation --------------
        const basicThreshold =    { min: 5,  normal: px*0.5, high: px*2 };
        const advancedThreshold = { min: 30, normal: px*0.1, high: px*0.5 };
        const threshold = isBasic ? basicThreshold : advancedThreshold;
        //--------
        const valueCount = level ? threshold.high : threshold.normal;
        let agrCount =  epoch / valueCount;
        agrCount = (agrCount < threshold.min) ? threshold.min : agrCount;
        // -------------------------------------------------
        const {customDebugAgrCount} = this.state;
        if(customDebugAgrCount > 0){
            return customDebugAgrCount;
        }
        // AGRCOUNT - represents number of seconds to aggregate together
        return Math.ceil(agrCount);
    }

    loadBasicGraph = (data, ip, now) => {
        const { epoch } = data.ipDetails;
        const count = this.getAgrCount(epoch, 'basic');
        const probe = data.ipDetails.probe;
        const callback = this.setGraphData;
        DataBasic.loadData(ip, now, epoch, count, probe, callback);
    }

    loadAdvancedGraph = (data, ip, now) => {
        const { epoch } = data.ipDetails;
        const count = this.getAgrCount(epoch, 'advanced');
        const probe = data.ipDetails.probe;
        const callback = this.setGraphData;
        DataAdvanced.loadData(ip, now, epoch, count, probe, callback);
    }

    setGraphData = (dataO, data, timestamp, stats) => {
        const componentProbe = Utils.getPropertyOfObject(['ipDetails', 'probe'], this.props);
        const componentIP = Utils.getPropertyOfObject(['selectedIP'], this.props);
        const dataProbe = Utils.getPropertyOfObject(['probe'], stats);
        const dataIP = Utils.getPropertyOfObject(['ip'], stats);
        // local probe or IP might have change before the callback returned
        if (componentProbe === dataProbe && componentIP === dataIP) {
            let graphDataNew = data;
            if (this.props.ipDetails.graphType === 'advanced') {
                graphDataNew = DataAdvanced.getDisplayData(data, this.props.theme.shadowReverse);
            }
            this.setState({
                originalData: dataO,
                graphData: graphDataNew,
                stats,
            });
        }
    }

    getID = () => {
        if (this.props.ipDetails.graphType === 'advanced') {
            return 'advanced-graph-wrap';
        }
        return 'basic-graph-wrap';
    }

    getTrueTimeFrame = () => {
        const data = this.state.graphData;
        const ipData = this.props.ipDetails;
        if (Utils.isSet(data) && Utils.isSet(data.epoch)) {
            return { to: ipData.endPoint, epoch: data.epoch };
        }
        return { to: ipData.endPoint, epoch: ipData.epoch };
    }

    setEpochFromZoom = (from, to, res = true) => {
        const epoch = to - from;
        this.props.setEpochFromZoom(epoch, to, res);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ipDetails != this.props.ipDetails ||
            prevProps.selectedIP != this.props.selectedIP ||
            prevProps.theme != this.props.theme ||
            prevProps.isNarrow != this.props.isNarrow ||
            prevState.customDebugAgrCount != this.state.customDebugAgrCount
        ) {
            this.loadGraph(this.props, this.props.selectedIP, this.props.ipDetails.endPoint);
        }
        if (Utils.isSet(this.props.generateURL)) {
            this.props.generateURL();
        }
    }

    componentDidMount() {
        if (Utils.isSet(this.props.selectedIP)) {
            this.loadGraph(this.props, this.props.selectedIP, this.props.ipDetails.endPoint);
        }
    }

    render() {
        let hide = Utils.getPropertyOfObject(['hideGraphInfo'], this.props, false);
        return (
            <div className='graph-wrap' id={this.getID()}>
                {!hide && <HeaderWrap {...this.props} switchYAxis={this.switchYAxis} />}
                <div className='graph-inner-wrap'>
                    {!hide &&
                        <InnerWrap
                            {...this.props}
                            setUTC={this.setUTC}
                            isUTC={this.state.isUTC}
                            setZoom={val => this.setChartInstance('zoomLevel', val)}
                            zoomLevel={this.state.zoomLevel}
                        />
                    }
                    <div className={'graph-inner__custom-count'}>
                        <input
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    this.setState({customDebugAgrCount: e.target.value})
                                }
                            }}
                        />
                    </div>
                    <Graph
                        graphData={this.state.graphData}
                        originalData={this.state.originalData}
                        graphType={this.props.ipDetails.graphType}
                        ipDetails={this.props.ipDetails}
                        selectedIP={this.props.selectedIP}
                        setChartInstance={this.setChartInstance}
                        setEpochFromZoom={this.setEpochFromZoom}
                        isMobile={this.props.isMobile}
                        theme={this.props.theme}
                        isUTC={this.state.isUTC}
                        chartWidth={Utils.getChartLineWidth(this.props.isMobile, this.props.isNarrow)}
                        zoomLevel={this.state.zoomLevel}
                        stats={this.state.stats}
                        noZoom={Utils.getPropertyOfObject(['noZoom'], this.props, false)}
                    />
                    {!hide &&
                        <GraphFooter
                            {...this.props}
                            chart={this.state.chart}
                            graphData={this.state.graphData}
                            stats={this.state.stats}
                            pxWidth={Utils.getChartLineWidth(this.props.isMobile, this.props.isNarrow)}
                        />
                    }
                </div>
                {!hide &&
                    <TraceLegend
                        {...this.props}
                        chart={this.state.chart}
                        trueTime={this.getTrueTimeFrame()}
                    />
                }
            </div>
        );
    }
}

export default GraphContainer;
