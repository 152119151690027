import React from 'react';
import Utils from '../../js/utils.js';
import ModalWrapper from './modalWrapper.jsx';
import AutoGroupSettings from '../common/autoGroupSettings.jsx';
import AlertsSettings from '../common/groupAlerting.jsx';

/**
* PROPS
* path - [arr] - Array of string, left to right, each represents category (e.g ['10-G','10G-SUB_CATEGORY])
* category - [string] - Category name
* slaveList - [obj] - {'AMS':'1.1.1.1', 'LON': '2.2.2.2', ...}
* showAutoGroupSettings [bool] - turns on/off the functionality to setup/edit auto-group settings
* isNewCategory [bool] - this option is true only if new auto-group gets created otherwise it is edit mode
*/

class CategorySettingsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        deleteOption: false,
        activeTab: 'default', // [default, general, alerts ]
        // category
        aliasInput: Utils.isSet(this.props.category) ? this.props.category : '',
        // autogroup
        isAutoGroup: false,
        agNetwork: '',
        agCount: 1,
        agSlaves: [],
        // alerting
        lossThreshold: 0,
        latencyThreshold: 0,
        timeThreshold: 0,
        pushNotifyA: [],
    })

    openModal = () => {
        this.setState({ ...this.getInitialState() })
        this.loadData();
    }

    loadData = () => {
        const sharedSettings = { dataLoadedFlag: true, activeTab: 'general' };
        if (this.props.isNewCategory) {
            this.setState({ ...sharedSettings });
        } else {
            let catName = this.props.path.join('->') + '->';
            const url = `/group/${catName}`;
            const successCallback = data => this.setState((prevState) => {
                const newState = {
                    ...Object.assign({}, prevState, data),
                    ...sharedSettings
                };
                return newState;
            });
            const errorCallback = data => this.setState({ ...sharedSettings });
            const data = Utils.formApiData(url, true, 'json', successCallback, errorCallback);
            Utils.performGETcall(data);
        }
    }

    setStateByKey = (...args) => {
        if (args.length === 1) {
            const func = args[0];
            this.setState(func);
        } else {
            const key = args[0];
            const value = args[1];
            this.setState({ [key]: value });
        }
    }

    /**
     * @param val - [bool] - based on bool show access to delete option
     */
    setDeleteOption = val => this.setState({ deleteOption: val });

    /**
     * generic callbacks in a form suited for modal-wrapper
     * @param modalCallback - [ref] - reference to a modal-wrapper function that handles api callbacks
     */
    getGenericCallback = (modalCallback) => {
        let callbacks = {};
        callbacks.success = succ => modalCallback({ verdict: true });
        callbacks.error = err => modalCallback({ verdict: false, errorPrompt: err.error });
        return callbacks;
    }

    /**
     * deletes a category with all the ips in it, from the config
     * @param modalCallback - [ref] - reference to a modal-wrapper function that handles api callbacks
     */
    deleteCategory = (modalCallback) => {
        let cats = this.props.path;
        cats = cats.slice(0, cats.length);
        const oldName = cats.join('->') + '->';
        const url = '/group/' + oldName.toUpperCase();
        const callbacks = this.getGenericCallback(modalCallback);
        const data = Utils.formApiData(url, true, 'json', callbacks.success, callbacks.error);
        Utils.performGETcall(data, 'DELETE');
    }

    /**
     * create datastructure and send the save request to API
     * @param modalCallback
     */
    saveChanges = (e, modalCallback, showLoading) => {
        e.preventDefault();
        showLoading();
        const { path } = this.props;
        let cats = (Utils.isSet(path)) ? [...path] : [];
        let newName = '';
        if (cats.length > 1) { // if category is a subcategory, then connect all the names of categories above
            newName = cats.slice(0, cats.length - 1).join('->') + '->';
        }
        newName += this.state.aliasInput + '->';
        let oldName = (Utils.isSet(path)) ? `${cats.join('->')}->` : newName;
        // Form Data Structure
        let dataState = this.state;
        let dataStructure = {};
        if (oldName !== newName) { // newName should be included in data, bcs it is indeed different and changed
            dataStructure.newname = newName.toUpperCase();
            const url = '/group/' + oldName.toUpperCase();
            const callbacks = this.getGenericCallback(modalCallback);
            const data = Utils.formApiData(url, true, 'json', callbacks.success, callbacks.error);
            data.contentType = 'application/json';
            data.data = dataStructure;
            Utils.performPUTcall(data, 'PUT');
        } else {
            dataStructure.isPublic = false; // this function is not yet implemented
            if (dataState.lossThreshold > 0) { dataStructure.lossThreshold = dataState.lossThreshold;}
            if (dataState.latencyThreshold > 0) { dataStructure.latencyThreshold = dataState.latencyThreshold;}
            if (dataState.timeThreshold > 0) { dataStructure.timeThreshold = dataState.timeThreshold;}
            if (dataState.latencyThreshold > 0 || dataState.lossThreshold > 0) { dataStructure.pushNotifyA = dataState.pushNotifyA;}
            if (Utils.isSet(dataState.agNetwork)) {
                dataStructure.isAutoGroup = true;
                dataStructure.agNetwork = dataState.agNetwork;
                dataStructure.agCount = Number(dataState.agCount);
                dataStructure.agSlaves = dataState.agSlaves;
            }
            // API - CALL
            const url = '/group/' + oldName.toUpperCase();
            const callbacks = this.getGenericCallback(modalCallback);
            const data = Utils.formApiData(url, true, 'json', callbacks.success, callbacks.error);
            data.contentType = 'application/json';
            data.data = dataStructure;
            Utils.performPUTcall(data, 'POST');
        }
    }

    getTabContent = () => {
        const { activeTab }  = this.state;
        switch (activeTab) {
            case 'general':
                return this.getGeneralTab();
            case 'alerts':
                return (
                    <AlertsSettings
                        lossThreshold={this.state.lossThreshold}
                        latencyThreshold={this.state.latencyThreshold}
                        timeThreshold={this.state.timeThreshold}
                        pushNotifyA={this.state.pushNotifyA}
                        setStateByKey={this.setStateByKey}
                        isNewCategory={this.props.isNewCategory}
                    />
                );
            default:
                return (<span>Loading ... </span>);
        }
    }

    getGeneralTab = () => (
        <div>
            <div className='alias-change'>
                <label className="label-title">
                    <span>ALIAS <i className="fa fa-star"/></span>
                </label>
                <input
                    type='text'
                    value={this.state.aliasInput}
                    onChange={e => this.setState({ aliasInput: e.target.value })}
                    required
                />
            </div>
            { (this.props.showAutoGroupSettings || this.state.isAutoGroup) &&
                <AutoGroupSettings
                    agNetwork={this.state.agNetwork}
                    agCount={this.state.agCount}
                    agSlaves={this.state.agSlaves}
                    slaveList={this.props.slaveList}
                    setStateByKey={this.setStateByKey}
                />
            }
        </div>
    );

    getTrigger = ({ action }) => {
        const isNew = this.props.isNewCategory;
        const additionalClass = (isNew) ? 'with-border' : '';
        const icon = (isNew) ? 'plus' : 'cog';
        return (
            <a className={'trigger cat-settings-trigger ' + additionalClass} onClick={action}>
                <i className={'fa fa-' + icon } />
            </a>
        );
    }

    render() {
        const styles = { content: { maxWidth: '1000px', height: 'auto' } };
        const Trigger = this.getTrigger;
        const { activeTab } = this.state;
        const headline = (this.props.isNewCategory) ? 'Set up a new category' : `Set ${this.props.category} properties`;
        const renderComponent = (confirmCallback, showLoading) => (
            <form id='set-cat-form' onSubmit={e => this.saveChanges(e,confirmCallback, showLoading)}>
                <h2>{headline}</h2>
                <div className="tab-slider--nav">
                    <ul className="tab-slider--tabs">
                        <li
                            className={'tab-slider--trigger ' + (activeTab === 'general' ? 'active' : '')}
                            onClick={() => this.setState({ activeTab: 'general' })}
                        >
                            Category
                        </li>
                        <li
                            className={'tab-slider--trigger ' + (activeTab === 'alerts' ? 'active' : '')}
                            onClick={() => this.setState({ activeTab: 'alerts' })}
                        >
                            Alerts
                        </li>
                    </ul>
                </div>
                <div className="tab-content">
                    {this.getTabContent()}
                </div>
                <div className='butts multi'>
                    <button type='submit' className='btn modal-confirm'>Save</button>
                    { !this.props.isNewCategory &&
                    <div className='delete-cat'>
                        <span className='trash-ip' onClick={() => this.setDeleteOption(true)}>
                            <i className='fa fa-trash-o' />
                            <span>Delete this Category</span>
                        </span>
                        { this.state.deleteOption &&
                            <span id='delete-cat-confirm'>
                                <span className='btn btn-delete' onClick={() => this.deleteCategory(confirmCallback)}>Yes</span>
                                <span className='btn no' onClick={() => this.setDeleteOption(false)}>No</span>
                            </span>
                        }
                    </div>
                    }
                </div>
            </form>
        );
        return (
            <ModalWrapper
                Trigger={Trigger}
                customWrapClass={(this.props.isNewCategory) ? 'modal-wrap-add-autogroup' : ''}
                style={styles}
                openModal={this.openModal}
                modalID='modal-set-cat'
                redirect={this.props.reloadConfig}
                refs={{}}
                render={renderComponent}
            />
        );
    }
}

export default CategorySettingsModal;
