import React from 'react';
import Modal from 'react-modal';
import Utils from '../../js/utils.js';

/** *
 * PROPS
 * setValue - [func] - set value
 * valType - [string] - serves for modal's description
 */

class AddValModal extends React.Component {
    constructor(props) {
        super(props);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.state = {
            modalIsOpen: false,
            disabled: true,
            inputVal: '',
        };
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    onInputChange(e) {
        const { value } = e.target;
        this.setState({
            inputVal: value,
            disabled: value.trim().length < 1,
        });
    }

    getStyles() {
        const style = JSON.parse(JSON.stringify(Utils.modals.config));
        style.content.width = '500px';
        style.content.height = '270px';
        style.content.minHeight = '270px';
        return style;
    }

    saveChanges(e) {
        e.preventDefault();
        this.props.setValue(this.state.inputVal.trim());
        this.setState({
            modalIsOpen: false,
            disabled: true,
            inputVal: '',
        });
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        return (
            <li key='+'>
                <a value='+' className='addVal' onClick={this.openModal}>{`Add ${this.props.valType}`}</a>
                {this.state.modalIsOpen
                && (
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={this.getStyles()}
                        contentLabel='addValModal'
                    >
                        <div className='valueModal modal'>
                            <button onClick={this.closeModal} className='modal-close' />
                            <form>
                                <h2>Create new category</h2>
                                <div>
                                    <label className='label-title'>
                                        <span>CATEGORY NAME</span>
                                    </label>
                                </div>
                                <input
                                    type='text'
                                    id='valueModalInput'
                                    className='input-blue'
                                    value={this.state.inputVal}
                                    onChange={this.onInputChange}
                                    autoFocus
                                />
                                <div className='butts'>
                                    <button
                                        className='modal-confirm'
                                        onClick={this.saveChanges}
                                        disabled={this.state.disabled}
                                    >
                                    Create
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal>
                )
                }
            </li>
        );
    }
}

export default AddValModal;
