/* global $:true */

import React from 'react';
import SVGInline from 'react-svg-inline';
import update from 'immutability-helper';
import Utils from '../../js/utils.js';
import GraphContainer from '../graph/graphContainer.jsx';
import IP from '../sidePanel/sidebar/ip.jsx';
import iconSearch from '../../../less/libs/icons/icon-search.svg';

class Search extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getInitialState = this.getInitialState.bind(this);
        this.setSearchItems = this.setSearchItems.bind(this);
        this.changeIP = this.changeIP.bind(this);
        this.closeSearch = this.closeSearch.bind(this);
        this.bindEscSearch = this.bindEscSearch.bind(this);
        this.showSearch = this.showSearch.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.filterDataByAS = this.filterDataByAS.bind(this);
        this.filterDataBySlave = this.filterDataBySlave.bind(this);
        this.filterDataByString = this.filterDataByString.bind(this);
        this.filterDataBySubnet = this.filterDataBySubnet.bind(this);
        this.orderData = this.orderData.bind(this);
        this.getIPcolor = this.getIPcolor.bind(this);
        this.changeSortAttr = this.changeSortAttr.bind(this);
        this.getSearchTips = this.getSearchTips.bind(this);
        this.getDisplayData = this.getDisplayData.bind(this);
        this.getDisplayDataMobile = this.getDisplayDataMobile.bind(this);
        this.changeLiveIPs = this.changeLiveIPs.bind(this);
        this.getResults = this.getResults.bind(this);
        this.showGraph = this.showGraph.bind(this);
        this.moveToQuicklist = this.moveToQuicklist.bind(this);
        this.offClick = this.offClick.bind(this);
        this.deleteHistory = this.deleteHistory.bind(this);
        this.onFocusIn = this.onFocusIn.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.markAllInputs = this.markAllInputs.bind(this);
        this.setInputs = this.setInputs.bind(this);
        this.filterLiveIps = this.filterLiveIps.bind(this);
        this.getResultData = this.getResultData.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
        this.moveToQuickLimit = 101;
    }

    getInitialState() {
        return {
            allData: [],
            inputValues: {}, // key is IP and value is index in results or -1 if not included
            sortOption: 'ip',
            sortIsAscend: true,
            showOptions: false,
            results: [],
            detailID: NaN,
            inputValOnClose: '',
            liveIPs: false,
            lastSelection: { id: -1, bool: false },
            selectAll: false,
            selectedCount: 0,
            searchInput: ''
        };
    }

    setSearchItems(props) {
        let data = props.config;
        if (Utils.isNotSet(data)) {
            return [];
        }
        data = data.ping.ips;
        const options = [];
        const inputValues = {};
        for (const ip in data) {
            const opt = {};
            // set IP
            opt.ip = ip;
            // set title
            let title = data[ip].desc;
            if (title == undefined) {
                title = data[ip];
            }
            opt.alias = title;
            // set slaves
            const slArr = props.config.ping.ips[ip].slaves;
            if (Utils.isSet(slArr)) {
                opt.slaves = slArr.toString();
            }
            // set category and AS
            const { cat } = data[ip];
            opt.cat = Utils.isSet(cat) ? typeof cat === 'string' ? cat : cat[0] : 'IPS';
            opt.as = data[ip].as;

            // push created structure in searchable items array
            options.push(opt);
            inputValues[ip] = false; // by default all inputs are unchecked
        }

        this.setState({
            allData: options,
            inputValues,
        });
    }

    changeIP(e) {
        const ip = $(e.target).parent('tr').attr('data-ip');
        this.props.updateSelectedIp(ip);
        this.closeSearch();
    }

    closeSearch() {
        $(document).off('keydown');
        const val = this.refs.searchInput.value;
        this.refs.searchInput.value = '';
        this.setState((prevState) => {
            let obj = this.setInputs(prevState, false);
            return {
                showOptions: false,
                detailID: NaN,
                inputValOnClose: Utils.isSet(val) ? val : prevState.inputValOnClose,
                lastSelection: { id: -1, bool: false },
                selectAll: false,
                selectedCount: 0,
                ...obj
            };
        });
    }

    bindEscSearch() {
        const scope = this;
        $(document).on('keydown', (e) => {
            if (e.keyCode === 27) { // ESC
                scope.closeSearch();
            }
        });
    }

    showSearch(e) {
        const scope = this;
        let show = true;
        if (this.props.isMobile && this.state.showOptions) {
            show = false;
        }
        this.setState({
            showOptions: show,
        });
    }

    onInputChange(e) {
        const val = e.target.value.toString().toLowerCase();
        let data = this.getResultData(val);
        this.setState({
            inputValOnClose: '',
            showOptions: true,
            searchInput: val,
            ...data
        });
    }

    getResultData(inputVal, liveIPs = this.state.liveIPs) {
        let data = [];
        if (Utils.isSet(inputVal)) {
            const subRegex = Utils.getSubnetRegex();
            if (subRegex.test(inputVal)) {
                data = this.filterDataBySubnet(inputVal);
            } else if (inputVal.startsWith('as')) {
                data = this.filterDataByAS(inputVal);
            } else {
                data = this.filterDataByString(inputVal);
            }
        }
        const lossArr = this.props.colorList;
        if (liveIPs) {
            let newResults = data.filter((val) => {
                let ip = val.ip;
                let ipLoss = lossArr[ip];
                if (Utils.isSet(ipLoss)) {
                    ipLoss = ipLoss.AVERAGE;
                    return ipLoss < 51;
                }
                return false;
            });
            return { results: newResults };
        }
        return { results: data };
    }

    inputClick(e, id, list) {
        e.preventDefault();
        e.stopPropagation();
        const inputs = this.state.inputValues;
        const last = this.state.lastSelection;
        let count = this.state.selectedCount;
        if (last.id >= 0 && e.shiftKey) {
            let from = last.id;
            let to = id;
            if (from > to) {
                const temp = from;
                from = to;
                to = temp;
            }
            const obj = Object.assign({}, inputs);
            for (let i = from; i <= to; i++) {
                const key = list[i].ip;
                obj[key] = last.bool;
                count += last.bool ? 1 : -1;
            }
            // count -1 is (-1) because wa are including both ends, but one end was alredy counted
            // with a initial single click
            this.setState({
                inputValues: obj,
                lastSelection: { id: -1, bool: false },
                selectedCount: count - 1,
            });
        } else {
            const key = list[id].ip;
            const bool = inputs[key];
            count += !bool ? 1 : -1;
            const newState = update(this.state, {
                inputValues: { [key]: { $set: !bool } },
                lastSelection: { $set: { id, bool: !bool } },
                selectedCount: { $set: count },
            });
            this.setState(newState);
        }
    }

    markAllInputs() {
        this.setState((prevState) => {
            const bool = !prevState.selectAll;
            let obj = this.setInputs(prevState, bool);
            return { selectAll: bool, ...obj };
        });
    }

    setInputs(prevState, bool) {
        const obj = Object.assign({}, prevState.inputValues);
        const result = Object.assign({}, prevState.results);
        const liveOnly = prevState.liveIPs;
        let count = 0;
        Object.keys(result).forEach((key) => {
            let ip = result[key].ip;
            const ipLoss = this.props.colorList[ip];
            if ((liveOnly && ipLoss.AVERAGE < 51) || !liveOnly) {
                obj[ip] = bool;
                count += 1;
            }
        });
        return {
            inputValues: obj,
            selectedCount: (bool) ? count : 0
        };
    }

    filterDataByAS(as) {
        const data = this.state.allData;
        const filtered = [];
        let slave = '';
        if (as.includes(':')) {
            const arr = as.split(':');
            as = arr[0].trim();
            slave = arr[1].trim().toUpperCase();
        }
        const asNum = as.substring(2);
        for (const index in data) {
            const { ip, as, alias } = data[index];
            const ipAS = (Utils.isSet(as)) ? as.toString().toLowerCase() : '';
            const ipAlias = (Utils.isSet(alias)) ? alias.toString().toLowerCase() : '';
            if (ipAS.startsWith(asNum) || ipAlias.includes('as' + asNum)) {
                filtered.push(data[index]);
            }
        }
        if (Utils.isSet(slave)) {
            return this.filterDataBySlave(slave, filtered);
        }
        return filtered;
    }

    filterDataBySlave(slave, data = this.state.allData) {
        const filtered = [];
        for (const index in data) {
            const { slaves } = data[index];
            const ipSlaves = (Utils.isSet(slaves)) ? slaves.toString().toUpperCase() : '';
            if (ipSlaves.includes(slave)) {
                filtered.push(data[index]);
            }
        }
        return filtered;
    }

    filterDataByString(val) {
        const data = this.state.allData;
        val = val.toString().toUpperCase();
        const filtered = [];
        let
            slave = '';
        if (val.includes(':')) {
            const arr = val.split(':');
            val = arr[0];
            slave = arr[1];
        }
        for (const index in data) {
            const alias = data[index].alias.toString().toUpperCase();
            const { ip } = data[index];
            if (ip.includes(val) || alias.includes(val)) {
                filtered.push(data[index]);
            }
        }
        if (Utils.isSet(slave)) {
            return this.filterDataBySlave(slave, filtered);
        }
        return filtered;
    }

    filterDataBySubnet(subnet) {
        const data = this.state.allData;
        const filtered = [];
        for (const index in data) {
            const { ip } = data[index];
            if (Utils.inSubNet(ip, subnet)) {
                filtered.push(data[index]);
            }
        }
        return filtered;
    }

    orderData() {
        let data = this.state.results
        if(this.props.isMobile && data.length === 0 && this.state.searchInput === ''){
            data = this.state.allData;
        }
        const sortAttr = this.state.sortOption;
        if (this.state.sortIsAscend) {
            return data.sort((a, b) => -(a[sortAttr] < b[sortAttr]) || +(a[sortAttr] != b[sortAttr]));
        }
        return data.sort((a, b) => -(a[sortAttr] > b[sortAttr]) || +(a[sortAttr] != b[sortAttr]));

        return data;
    }

    getIPcolor(arr) {
        const loss = arr == undefined ? undefined : arr.AVERAGE;
        const color = Utils.getLossColor(loss);
        return color;
    }

    changeSortAttr(e) {
        const val = e.target.getAttribute('data-name');
        if (val != this.state.sortOption) {
            this.setState({
                sortOption: val,
            });
        } else {
            this.setState({
                sortIsAscend: !this.state.sortIsAscend,
            });
        }
    }

    getSearchTips() {
        return (
            <div className='search-tips'>
                <h2> HOW TO SEARCH </h2>
                <div className='content'>
                    <span className='title'>IP</span>
                    <p>
                        {' '}
                        <strong>[ ip ]</strong>
                        {' '}
                        (example : '1.1.1.1')
                        {' '}
                    </p>

                    <span className='title'>TEXT</span>
                    <p>
                        <strong>[ text ]</strong>
                        {' '}
                        (example : 'work')
                        <br />
                        <strong>[ text:slave_name ]</strong>
                        {' '}
                        (example : 'work:AMS')
                        <br />
                    </p>

                    <span className='title'>AS</span>
                    <p>
                        <strong>[ as'number' ]</strong>
                        {' '}
                        (example: 'as6')
                        <br />
                        <strong>[ as'number' : slave_name ]</strong>
                        {' '}
                        (example: 'as6:AMS')
                        <br />
                    </p>


                    <span className='title'>SUBNET</span>
                    <p>
                        <strong>[ ip/subnet ]</strong>
                        {' '}
                        (example: '1.1.1.1/24')
                        <br />
                    </p>
                    <span className='title'>MOVE TO QUICKLIST</span>
                    <p>
                        Button moves search result in the sidepanel's quicklist. Max move limit is 150 IPs.
                    </p>
                </div>
            </div>
        );
    }

    getDisplayData() {
        const entrys = [];
        const list = this.orderData();
        let threshold = 0;
        let backgClass = 'dark';
        for (let i = 0; i < list.length; i++) {
            if (threshold > 200) {
                return entrys;
            }
            backgClass = backgClass == 'light' ? 'dark' : 'light';
            const temp = list[i];
            if (Utils.isSet(temp.slaves)) {
                const ipLoss = this.props.colorList[temp.ip];
                const pl = this.getIPcolor(ipLoss);
                const style = { fill: pl };
                let { slaves } = temp;
                slaves = slaves.length > 20 ? `${slaves.substring(0, 20)}...` : slaves;
                const highlight = this.state.detailID == i ? 'active' : '';
                let cat = temp.cat.split('->');
                cat = (cat.length > 2 ? '...' : '') + cat[cat.length - 2];
                if (Utils.isNotSet(temp.cat)) {
                    cat = '-no-category-';
                }

                threshold += 1; // actual insert into the search
                entrys.push(
                    <tr key={i} onClick={this.changeIP} data-ip={temp.ip} className={`${highlight} ${backgClass}`}>
                        <td onClick={e => this.inputClick(e, i, list)} className='first'>
                            <label className='inp-check inp-check-no-event'>
                                <input
                                    type='checkbox'
                                    checked={this.state.inputValues[temp.ip]}
                                    onChange={() => {}}
                                />
                                <span />
                            </label>
                        </td>
                        <td className='ip'>{temp.ip}</td>
                        <td className='alias'>{temp.alias}</td>
                        <td className='pl'>
                            <svg className={this.colorHidden(pl)}>
                                <circle cx='6' cy='6' r='6' style={style} />
                            </svg>
                        </td>
                        <td className='slaves'>{slaves}</td>
                        <td className='cat'>{cat}</td>
                        <td className='cat'>{temp.as}</td>
                        <td className='detail last'>
                            <a data-id={i} onClick={this.showGraph} className='show-graph'>
                                <i className='fa fa-bar-chart' />
                            </a>
                        </td>
                    </tr>,
                );
                if (this.state.detailID == i) {
                    const slave = slaves.split(',')[0];
                    const graphConf = {
                        endPoint: Utils.getTimeNow(),
                        epoch: 86400,
                        probe: `slave=${slave}`,
                        graphType: 'advanced',
                        accuracy: 'normal',
                        customEpoch: false,
                        isFavourite: false,
                    };
                    entrys.push(
                        <tr key={`${i}graph`}>
                            <td colSpan='7' className='graph'>
                                <GraphContainer
                                    hideGraphInfo
                                    selectedIP={temp.ip}
                                    ipDetails={graphConf}
                                    theme={this.props.theme}
                                    noZoom={true}
                                />
                            </td>
                        </tr>,
                    );
                }
            }
        }
        return entrys;
    }

    getDisplayDataMobile() {
        const entrys = [];
        const list = this.orderData();
        let threshold = 0;
        for (let i = 0; i < list.length; i++) {
            if (threshold > 200) {
                return entrys;
            }
            const temp = list[i];
            if (Utils.isSet(temp.slaves)) {
                const pl = this.getIPcolor(this.props.colorList[temp.ip]);
                const alias = (Utils.isNotSet(temp.alias)) ? temp.ip : temp.alias;
                const style = { fill: pl };
                let { slaves } = temp;
                slaves = slaves.length > 20 ? `${slaves.substring(0, 20)}...` : slaves;
                threshold += 1; // actual insert into the search
                entrys.push(
                    <tr key={i} onClick={this.changeIP} data-ip={temp.ip}>
                        <td className='pl'>
                            <svg className={this.colorHidden(pl)}>
                                <circle cx='6' cy='6' r='6' style={style} />
                            </svg>
                        </td>
                        <td className='alias'>{alias}</td>
                    </tr>,
                );
            }
        }
        if (entrys.length === 0) {
            return (
                <tr key='empty'>
                    <td colSpan="2"> No Results </td>
                </tr>
            );
        }
        return entrys;
    }

    colorHidden(color) {
        if (color == '' || color == undefined) {
            return 'hidden';
        }
        return '';
    }

    changeLiveIPs() {
        this.setState((prevState) => {
            let bool = !prevState.liveIPs;
            let data = [];
            if (bool) {
                data = this.filterLiveIps(prevState, bool);
            } else {
                data = this.getResultData(prevState.searchInput, bool);
            }
            return {
                liveIPs: bool,
                selectedCount: (prevState.selectedCount > 0 ? prevState.selectedCount : 0),
                ...data
            };
        });
    }

    filterLiveIps(prevState, liveOnly) {
        let results = prevState.results;
        const lossArr = this.props.colorList;
        const inputs = Object.assign({}, prevState.inputValues);
        let { selectedCount } = prevState;
        let newResults = results.filter((val) => {
            let ip = val.ip;
            let ipLoss = lossArr[ip];
            if (Utils.isSet(ipLoss)) {
                ipLoss = ipLoss.AVERAGE;
                if (liveOnly && inputs[ip]) {
                    inputs[ip] = ipLoss < 51;
                    selectedCount += (ipLoss < 51) ? 0 : -1;
                }
                return (liveOnly && ipLoss < 51) || !liveOnly;
            }
            return false;
        });
        return { results: newResults, inputValues: inputs, selectedCount };
    }

    getResults() {
        if (this.props.isMobile) {
            return (
                <div className='result'>
                    <div className='btn-wrap'>
                        <button type='button' className='btn-close' onClick={this.closeSearch}> CLOSE SEARCH</button>
                    </div>
                    <table cellPadding='0' cellSpacing='0'>
                        <thead>
                            <tr className='head-row'>
                                <th
                                    onClick={this.changeSortAttr}
                                    data-name='packetLoss'
                                    className={this.state.sortOption == 'packetLoss' ? 'active' : ''}
                                >
                                PL
                                </th>
                                <th
                                    onClick={this.changeSortAttr}
                                    data-name='alias'
                                    className={this.state.sortOption == 'alias' ? 'active' : ''}
                                >
                                ALIAS
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getDisplayDataMobile()}
                        </tbody>
                    </table>
                </div>
            );
        }
        const lastVal = this.state.inputValOnClose;
        const noResults = (this.refs.searchInput.value.length > 0) && (this.state.results.length == 0);
        let count = this.state.selectedCount;
        let displayData = this.getDisplayData();
        count = count <= 0 ? this.state.results.length : count;
        return (
            <div className='result'>
                <div className='options'>
                    <button
                        type='button'
                        className={'move-to-quicklist move-to-quicklist--' + (count < this.moveToQuickLimit && count > 0 ? 'green' : 'red')}
                        onClick={this.moveToQuicklist}
                    >
                        {`To Quicklist [${count}]`}
                    </button>
                    <label className='inp-check' htmlFor='sortByTime'>
                        <input
                            type='checkbox'
                            id='sortByTime'
                            checked={this.state.liveIPs}
                            onChange={this.changeLiveIPs}
                            data-option='sortByTime'
                        />
                        <span>Live IPs</span>
                    </label>
                    <button className='close-search' onClick={this.closeSearch}> CLOSE SEARCH</button>
                </div>
                {noResults && <div className='no-search-results'><span>NO SEARCH RESULTS</span></div>}
                {this.state.results.length == 0 && this.getSearchTips()}
                {this.state.results.length > 0
                && (
                    <div>
                        {
                            Utils.isSet(lastVal)
                            && (
                                <h2>
                                    Last Search :
                                    {' '}
                                    {this.state.inputValOnClose}
                                    <a onClick={this.deleteHistory} className='fa fa-times' />
                                </h2>
                            )
                        }
                        <table>
                            <thead>
                                <tr>
                                    <th onClick={this.markAllInputs}>ALL</th>
                                    <th onClick={this.changeSortAttr} data-name='ip' className={this.state.sortOption == 'ip' ? 'active' : ''}>
                                        IP
                                        <svg className={`navigation-arrow ${this.state.sortIsAscend ? 'ascend' : 'descend'}`} viewBox='0 0 11 7'>
                                            <path d='M5.46 7c-.18 0-.33-.06-.45-.18L.18 1.99c-.24-.24-.24-.63 0-.9.24-.24.63-.24.9 0l4.38 4.38 4.38-4.38c.24-.24.63-.24.9 0 .24.24.24.63 0 .9L5.91 6.82c-.12.12-.3.18-.45.18z' />
                                        </svg>
                                    </th>
                                    <th onClick={this.changeSortAttr} data-name='alias' className={this.state.sortOption == 'alias' ? 'active' : ''}>
                                        ALIAS
                                        <svg className={`navigation-arrow ${this.state.sortIsAscend ? 'ascend' : 'descend'}`} viewBox='0 0 11 7'>
                                            <path d='M5.46 7c-.18 0-.33-.06-.45-.18L.18 1.99c-.24-.24-.24-.63 0-.9.24-.24.63-.24.9 0l4.38 4.38 4.38-4.38c.24-.24.63-.24.9 0 .24.24.24.63 0 .9L5.91 6.82c-.12.12-.3.18-.45.18z' />
                                        </svg>
                                    </th>
                                    <th onClick={this.changeSortAttr} data-name='packetLoss' className={this.state.sortOption == 'packetLoss' ? 'active' : ''}>
                                        PL
                                        <svg className={`navigation-arrow ${this.state.sortIsAscend ? 'ascend' : 'descend'}`} viewBox='0 0 11 7'>
                                            <path d='M5.46 7c-.18 0-.33-.06-.45-.18L.18 1.99c-.24-.24-.24-.63 0-.9.24-.24.63-.24.9 0l4.38 4.38 4.38-4.38c.24-.24.63-.24.9 0 .24.24.24.63 0 .9L5.91 6.82c-.12.12-.3.18-.45.18z' />
                                        </svg>
                                    </th>
                                    <th onClick={this.changeSortAttr} data-name='slaves' className={this.state.sortOption == 'slaves' ? 'active' : ''}>
                                        SLAVES
                                        <svg className={`navigation-arrow ${this.state.sortIsAscend ? 'ascend' : 'descend'}`} viewBox='0 0 11 7'>
                                            <path d='M5.46 7c-.18 0-.33-.06-.45-.18L.18 1.99c-.24-.24-.24-.63 0-.9.24-.24.63-.24.9 0l4.38 4.38 4.38-4.38c.24-.24.63-.24.9 0 .24.24.24.63 0 .9L5.91 6.82c-.12.12-.3.18-.45.18z' />
                                        </svg>
                                    </th>
                                    <th onClick={this.changeSortAttr} data-name='cat' className={this.state.sortOption == 'cat' ? 'active' : ''}>
                                        CATEGORY
                                        <svg className={`navigation-arrow ${this.state.sortIsAscend ? 'ascend' : 'descend'}`} viewBox='0 0 11 7'>
                                            <path d='M5.46 7c-.18 0-.33-.06-.45-.18L.18 1.99c-.24-.24-.24-.63 0-.9.24-.24.63-.24.9 0l4.38 4.38 4.38-4.38c.24-.24.63-.24.9 0 .24.24.24.63 0 .9L5.91 6.82c-.12.12-.3.18-.45.18z' />
                                        </svg>
                                    </th>
                                    <th onClick={this.changeSortAttr} data-name='as' className={this.state.sortOption == 'as' ? 'active' : ''}>
                                        AS
                                        <svg className={`navigation-arrow ${this.state.sortIsAscend ? 'ascend' : 'descend'}`} viewBox='0 0 11 7'>
                                            <path d='M5.46 7c-.18 0-.33-.06-.45-.18L.18 1.99c-.24-.24-.24-.63 0-.9.24-.24.63-.24.9 0l4.38 4.38 4.38-4.38c.24-.24.63-.24.9 0 .24.24.24.63 0 .9L5.91 6.82c-.12.12-.3.18-.45.18z' />
                                        </svg>
                                    </th>
                                    <th>DETAIL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayData}
                            </tbody>
                        </table>
                    </div>
                )
                }
            </div>
        );
    }

    showGraph(e) {
        let id = Number($(e.target).attr('data-id'));
        id = id == this.state.detailID ? NaN : id;
        this.setState({
            detailID: id,
        });
        e.stopPropagation();
    }

    moveToQuicklist() {
        let data = this.state.results;
        const count = this.state.selectedCount;
        if (count > 0 && count < data.length) { // inputs are selected
            const inputs = this.state.inputValues;
            const selection = Object.keys(inputs).filter(key => inputs[key]); // filter out selected IPs
            data = data.filter(i => selection.includes(i.ip)); // filter out only desired IPs
        }
        let keys = Object.keys(data);
        if (data.length > 0 && data.length > this.moveToQuickLimit) {
            keys = keys.slice(0, this.moveToQuickLimit);
        }
        const scope = this;
        const ipArr = [];
        const sortedKeys = keys.sort((a, b) => {
            a = data[a].alias.toString().toLowerCase();
            b = data[b].alias.toString().toLowerCase();
            return -(a < b) || +(a != b);
        });
        const dataArr = [];
        for (const ip in sortedKeys) {
            const server = data[sortedKeys[ip]];
            dataArr.push({
                ip: server.ip,
                title: server.alias,
                isfav: false,
            });
            ipArr.push(
                <IP
                    key={ip}
                    ip={server.ip}
                    title={server.alias}
                    color={scope.getIPcolor(this.props.colorList[server.ip])}
                    defaultColor={Utils.getLossColor(undefined)}
                    slave={undefined}
                    updateSelectedIp={scope.props.updateSelectedIp}
                    addValToQuickList={scope.props.addValToQuickList}
                />,
            );
        }
        const dataRecord = { data: { I_P_S: dataArr } };
        this.props.addValToQuickList(
            { isIp: false, name: 'SEARCH', data: dataRecord, 'data-ip': ipArr,}
            , true
        );
        this.closeSearch();
    }

    offClick() {
        const scope = this;
        $('.main-panel, #sidebar').off('click');
        if (this.state.showOptions) {
            this.bindEscSearch();
            $('.main-panel, #sidebar').on('click', () => {
                scope.closeSearch();
            });
        }
    }

    deleteHistory() {
        this.refs.searchInput.value = '';
        this.setState({
            inputValOnClose: '',
            results: [],
        });
    }

    onFocusIn() {
        if (this.props.isMobile || this.state.results.length > 0) {
            this.setState({ showOptions: true });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.config != nextProps.config) {
            this.setSearchItems(nextProps);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
        if (this.state != nextState) {
            return true;
        }
        if (this.props.colorList != nextProps.colorList
            || this.props.isMobile != nextProps.isMobile) {
            return true;
        }
        return false;
    }

    componentDidMount() {
        this.setSearchItems(this.props);
    }

    render() {
        const show = this.state.showOptions;
        const showClose = show && !this.props.isMobile;
        if (show && !this.props.isMobile) {
            this.offClick();
        }
        return (
            <div id='search' className='search-wrap'>
                <SVGInline svg={iconSearch} className='icon-search' />
                <input
                    type='text'
                    placeholder='Search IP...'
                    ref='searchInput'
                    className={this.state.results.length == 0 ? 'redBorder' : ''}
                    onFocus={this.onFocusIn}
                    value={this.state.searchInput}
                    onChange={this.onInputChange}
                    autoFocus={!this.props.isMobile}
                />
                {show && this.getResults()}
            </div>
        );
    }
}

export default Search;
