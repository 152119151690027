/* global $:true */

import React from 'react';
import { withTooltip } from 'react-tippy';
import Utils from '../../js/utils.js';
import ProbeSelection from '../common/ProbeSelection.jsx';
import CategorySelection from '../common/CategorySelection.jsx';
import ModalWrapper from './modalWrapper.jsx';

/** *
 * PROPS
 * reloadConfig - [func] - fetch and reload config
 * slaveList - [obj] - {'AMS':'1.1.1.1', 'LON': '2.2.2.2', ...}
 * categories - [obj] - {'CAT1':{'CAT1_SUB':{I_P_S[...]},I_P_S:[{ip: "1.1.1.1", title: "one", isfav: false},..]}}
 */

class AddIpModal extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.successRedirect = this.successRedirect.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.setProbesSelection = this.setProbesSelection.bind(this);
        this.getProbeSelection = this.getProbeSelection.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        inputIP: '',
        inputAlias: '',
        selectedProbes: [],
        selectedProbesReport: [],
        category: [],
        isFavChecked: false,
        tabSelection: 'probes',
        enableSaving: false,
        dontClose: false,
    })

    changeClosingLogic = () => this.setState(prevState => ({ dontClose: !prevState.dontClose }));

    setCategory = data => this.setState({ category: data });

    changeFavourites = () => {
        const { isFavChecked } = this.state;
        this.setState({ isFavChecked: !isFavChecked });
    }

    successRedirect(dontClose = this.state.dontClose) {
        if (dontClose) {
            this.setState({
                inputIP: '',
                inputAlias: '',
                enableSaving: false,
                isFavChecked: false,
            });
        } else {
            this.props.reloadConfig(true);
            this.setState(this.getInitialState());
        }
    }

    /** *
     *  @param data - [obj] - {'probe1':false, 'probe2':true, 'probe3'; false...}
     *  @param keyState - key in local state
     *  output -> ['probe2'...]
     */
    setSelectedProbes = (data, keyState = 'selectedProbes') => {
        if (keyState === 'selectedProbes') {
            this.setState(prevState => ({ selectedProbesReport: prevState.selectedProbesReport.filter(probe => data[probe]) }));
        }
        this.setState({ [keyState]: Object.keys(data).filter(probe => data[probe]) });
    }

    /** *
     * @param data - [obj] - {{'probe1':'1.1.1.1', 'probe2':'2.2.2.2', 'probe3'; '3.3.3.3'}}
     * output -> {{'probe1':'false', 'probe2':'true', 'probe3'; 'false'}} - bool is decided on IP's probes in config
     */
    setProbesSelection(selectedProbes = this.state.selectedProbes) {
        const data = this.props.slaveList;
        // const data = {
        //         "AMS-COGENT": false,
        //         "AMS-COREB": false,
        //         "AMS-DEFAULT": false,
        //         "AMS-GTT": false,
        //         "AMS-NTT": false,
        //         "AMS-SPARKLE": false,
        //         "AMS-TELIA": false,
        //         "ATL-COGENT": false,
        //         "ATL-COMCAST": false,
        //         "ATL-DEFAULT": false,
        //         "ATL-NTT": false,
        //         "ATL-TELIA": false,
        //         "BRA-DEFAULT": false,
        //         "C-LEA-AMS": false,
        //         "C-LEA-SGP": false,
        //         "C-OVH-CA": false,
        //         "C-OVH-FR": false,
        //         "CHI-DEFAULT": false,
        //         "CHI-GTT": false,
        //         "CHI-NTT": false,
        //         "CHI-TELIA": false,
        //         "DAL-COGENT": false,
        //         "DAL-DEFAULT": false,
        //         "DAL-TATA": false,
        //         "DEN-TATA": false,
        //         "DEN-TELIA": false,
        //         "FRA-COGENT": false,
        //         "FRA-COREB": false,
        //         "FRA-DEFAULT": false,
        //         "FRA-GTT": false,
        //         "FRA-TELIA": false,
        //         "HKG-COGENT": false,
        //         "HKG-DEFAULT": false,
        //         "HKG-TELIA": false,
        //         "HKG-VODAFONE": false,
        //         "JAK-DEFAULT": false,
        //         "LAX-NTT": false,
        //         "LAX-TATA": false,
        //         "LAX-TELIA": false,
        //         "LON-COGENT": false,
        //         "LON-COREB": false,
        //         "LON-DEFAULT": false,
        //         "LON-NTT": false,
        //         "LON-SPARKLE": false,
        //         "MAD-COGENT": false,
        //         "MAD-COREB": false,
        //         "MAD-DEFAULT": false,
        //         "MAD-LEVEL3": false,
        //         "MAD-NTT": false,
        //         "MAD-TELEFONICA": false,
        //         "MAD-TELIA": false,
        //         "MIA-COGENT": false,
        //         "MIA-COMCAST": false,
        //         "MIA-DEFAULT": false,
        //         "MIA-NTT": false,
        //         "MIL-DEFAULT": false,
        //         "MIL-SPARKLE": false,
        //         "MIL-TELIA": false,
        //         "NYC-COGENT": false,
        //         "NYC-COMCAST": false,
        //         "NYC-DEFAULT": false,
        //         "PAR-DEFAULT": false,
        //         "PAR-NTT": false,
        //         "PAR-SPARKLE": false,
        //         "PAR-TELIA": false,
        //         "PRG-10G-COGENT1": false,
        //         "PRG-10G-COREB": false,
        //         "PRG-10G-DEFAULT": false,
        //         "PRG-10G-GTT": false,
        //         "PRG-10G-TATA": false,
        //         "PRG-10G-TELIA": false,
        //         "PRG-10G-UPC": false,
        //         "PRG-COGENT": false,
        //         "PRG-COREB": false,
        //         "PRG-CRA-ZIZ": false,
        //         "PRG-GTT": false,
        //         "PRG-ISPA": false,
        //         "PRG-M247": false,
        //         "PRG-SH": false,
        //         "PRG-ZC-VPN": false,
        //         "SEA-COGENT": false,
        //         "SEA-DEFAULT": false,
        //         "SEA-GTT": false,
        //         "SEA-TELIA": false,
        //         "SGP-COGENT": false,
        //         "SGP-DEFAULT": false,
        //         "SGP-NME": false,
        //         "SGP-NTT": false,
        //         "SGP-SPARKLE": false,
        //         "SGP-TELIA": false,
        //         "SGP-VODAFONE": false,
        //         "STO-COREB": false,
        //         "STO-DEFAULT": false,
        //         "STO-GTT": false,
        //         "STO-TELIA": false,
        //         "TYO-COGENT": false,
        //         "TYO-DEFAULT": false,
        //         "TYO-NME": false,
        //         "TYO-NTT": false,
        //         "TYO-VODAFONE": false,
        //         "VIE-COGENT": false,
        //         "VIE-COREB": false,
        //         "VIE-DEFAULT": false,
        //         "VIE-NTT": false,
        //         "VIE-SPARKLE": false,
        //         "VIE-TELIA": false,
        //         "WAR-COGENT": false,
        //         "WAR-DEFAULT": false,
        //         "ZUR-COGENT": false,
        //         "ZUR-COREB": false,
        //         "ZUR-DEFAULT": false
        // };
        const outputFormat = {};
        const probeKeys = Object.keys(data);
        probeKeys.forEach((key) => {
            outputFormat[key] = selectedProbes.includes(key);
        });
        return outputFormat;
    }

    openModal = () => this.setState({ ...this.getInitialState() })

    saveChanges(e, confirmCallback) {
        e.preventDefault();
        const data = {};
        const ip = this.state.inputIP;
        data.desc = this.state.inputAlias;
        data.fav = this.state.isFavChecked;
        data.slaves = this.state.selectedProbes;
        let cat = Utils.isSet(this.state.category) ? this.state.category : [];
        cat = cat.length > 0 ? [cat.join('->') + '->'] : [''];
        if (this.state.selectedProbesReport.length > 0) {
            data.report = this.state.selectedProbesReport;
            cat.push('__Report->');
        }
        data.cat = cat;
        Utils.setConfigIp(data, ip, confirmCallback);
    }

    onInputChange(event) {
        Utils.modals.onIpChange($('.input-ip'));
        let ip = event.target.value;
        ip = ip.toString().trim();
        const regex = Utils.getIpRegex();
        this.setState({
            inputIP: ip,
            enableSaving: regex.test(ip),
        });
    }

    getProbeSelection() {
        const { tabSelection } = this.state;
        const reportProps = {};
        if (tabSelection === 'report') {
            reportProps.title = 'Locations for Reporting';
            reportProps.setSelectedProbes = data => this.setSelectedProbes(data, 'selectedProbesReport');
            reportProps.probesList = this.setProbesSelection(this.state.selectedProbesReport);
            reportProps.allowed = this.state.selectedProbes;
            reportProps.tab = 'report';
        }
        const InfoIcon = () => (<i className='fa fa-info-circle' />);
        const tooltipOptions = {
            style: { marginLeft: '20px' },
            theme: 'dark',
            position: 'right',
            animation: 'perspective',
        };
        tooltipOptions.title = `Reporting behavior is customized to client's needs. 
                                Learn more at https://cocopacket.com/reporting.html`;
        const ReportTooltip = withTooltip(InfoIcon, tooltipOptions);
        return (
            <div className='slave-selection-with-tabs'>
                <ul>
                    <li
                        className={tabSelection === 'probes' ? 'active' : ''}
                        onClick={() => {
                            this.setState({ tabSelection: 'probes' });
                        }}
                    >
                        probes
                    </li>
                    <li
                        className={tabSelection === 'report' ? 'active' : ''}
                        onClick={() => {
                            this.setState({ tabSelection: 'report' });
                        }}
                    >
                        report
                        <ReportTooltip />
                    </li>
                </ul>
                <ProbeSelection
                    ke={tabSelection}
                    tab='monitoring'
                    inputIP={this.state.inputIP}
                    setSelectedProbes={this.setSelectedProbes}
                    probesList={this.setProbesSelection()}
                    {...reportProps}
                />
            </div>
        );
    }

    render() {
        const Trigger = ({ action }) => (
            <a onClick={action} className='add-ip'>
                <i className='fa fa-plus' />
                Add IP
            </a>
        );
        const styles = { content: { height: 'auto', width: '80%' } };
        const renderComponent = confirmCallback => (
            <form id='add-new-ip-form' onSubmit={e => this.saveChanges(e, confirmCallback)}>
                <h2>Add IP address</h2>
                <span className='add-ip-address-box'>
                    <label className='label-title'>
                        <span> IP ADDRESS <i className="fa fa-star"/></span>
                        <a className='favourite-selection inline' onClick={this.changeFavourites}>
                            <i className={'fa fa-star' + (this.state.isFavChecked ? '' : '-o')} />
                                Add to favourites
                        </a>
                    </label>
                    <input
                        type='text'
                        className='input-ip'
                        value={this.state.inputIP}
                        onChange={this.onInputChange}
                        required="required"
                        autoFocus
                    />
                </span>
                <span className='add-ip-alias-box'>
                    <label className='label-title'>
                        <span>IP ALIAS</span>
                    </label>
                    <input
                        type='text'
                        className='input-alias'
                        value={this.state.inputAlias}
                        onChange={e => this.setState({ inputAlias: e.target.value })}
                    />
                </span>
                <CategorySelection
                    catList={[]}
                    category={this.state.category}
                    setCategory={this.setCategory}
                    selectedIP={this.state.inputIP}
                    categories={this.props.categories}
                />
                {this.getProbeSelection()}
                <div className='butts'>
                    <label className='inp-check'>
                        <input type='checkbox' checked={this.state.dontClose} onChange={this.changeClosingLogic} />
                        <span>Don't close after adding</span>
                    </label>
                    <button
                        type='submit'
                        className='modal-confirm'
                        disabled={!this.state.enableSaving || this.state.selectedProbes.length == 0}
                    >
                        Save IP settings
                    </button>
                </div>
            </form>
        );
        return (
            <ModalWrapper
                Trigger={Trigger}
                style={styles}
                modalID='modal-add-ip'
                redirect={this.successRedirect}
                openModal={this.openModal}
                dontClose={this.state.dontClose}
                render={renderComponent}
                showRequiredInfo={true}
                customWrapClass={'modalAddIp'}
            />
        );
    }
}

export default AddIpModal;
