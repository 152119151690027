/* global $:true */
import React from 'react';
import Select2 from 'react-select2-wrapper';
import ModalWrapper from './modalWrapper.jsx';
import Utils from '../../js/utils.js';


/**
 * PROPS
 * reloadConfig - [func] - reloads config, fetch api call
 * loadSlaveLost - [func] - reloads slave list, fetch api call
 * slaveList - [arr] - arr of slave names ['slave1', 'slave2', ...]
 *
 */

class AddSlaveModal extends React.Component {
    constructor(props) {
        super(props);
        this.showHideInstructions = this.showHideInstructions.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.getProbeTypes = this.getProbeTypes.bind(this);
        this.getAvailableSlavesForCopyAction = this.getAvailableSlavesForCopyAction.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            dontClose: false,
            enableSaving: false,
            howToHidden: true,
            version: '',
            // input - values
            inputIP: '',
            inputAlias: '',
            inputPort: '',
            architecture: 'amd64',
            copyFromSlave: '-',
        };
    }

    changeClosingLogic = () => this.setState(prevState => ({ dontClose: !prevState.dontClose }));

    successRedirect = () => {
        if (this.state.dontClose) {
            this.setState({
                inputIP: '',
                inputAlias: '',
                inputPort: '',
                enableSaving: false,
            });
        } else {
            this.props.reloadConfig(false);
            this.props.loadSlaveList();
            this.setState(this.getInitialState());
        }
    }

    openModal = () => this.setState({ ...this.getInitialState() })

    showHideInstructions() {
        // call is not wrapped by utils's api call wrapper - bcs it is not into default api path
        const val = this.state.howToHidden;
        const updateUrl = 'https://updates.cocopacket.cloud/cocopacket-slave/linux-amd64.json';
        if (this.state.version == '') {
            const scope = this;
            const successCallback = (data) => {
                scope.setState({ version: data.Version });
            };
            $.ajax({
                url: updateUrl,
                type: 'GET',
                async: true,
                dataType: 'json',
                success: successCallback,
            });
        }
        this.setState({ howToHidden: !val });
    }

    onInputIpChange = (e) => {
        let ip = e.target.value;
        ip = ip.toString().trim();
        Utils.modals.onIpChange($(this.refs.inputIP)); // bind IP regex action
        const regex = Utils.getIpRegex();
        this.setState({
            inputIP: ip,
            enableSaving: regex.test(ip),
        });
    }

    onInputPortChange = (e) => {
        let port = Number(e.target.value);
        const portBool = !Number.isNaN(port) && (port > 0) && (port <= 65535);
        port = Number.isNaN(port) ? '' : port;
        this.setState({ inputPort: port });
        // handle input control
        const errorMessage = 'Please, fill out a valid port';
        const inputSelector = $(this.refs.inputPort);
        const form = inputSelector.parent('form');
        if (portBool) {
            inputSelector.removeClass('input-red');
            inputSelector.addClass('input-green');
            $('.error-msg-form').remove();
            Utils.modals.enableSubmitting(form);
        } else {
            inputSelector.removeClass('input-green');
            inputSelector.addClass('input-red');
            if (!inputSelector.next('.error-msg-form').length) {
                const errorEl = `<div class='error-msg-form'>${errorMessage}</div>`;
                inputSelector.after(errorEl);
            }
            Utils.modals.disableSubmitting(form);
        }
        this.setState({
            enableSaving: portBool,
        });
    }

    onInputAliasChange = e => this.setState({ inputAlias: e.target.value });

    /**
     * generic callbacks in a form suited for modal-wrapper
     * @param modalCallback - [ref] - reference to a modal-wrapper function that handles api callbacks
     */
    getGenericCallback = (modalCallback) => {
        let callbacks = {};
        callbacks.success = succ => modalCallback({ verdict: true });
        callbacks.error = err => modalCallback({ verdict: false, errorPrompt: err.error });
        return callbacks;
    }

    saveChanges(e, modalCallback, showLoading) {
        e.preventDefault();
        showLoading();
        const callData = {};
        callData.ip = this.state.inputIP;
        callData.name = this.state.inputAlias;
        callData.port = Number(this.state.inputPort);
        callData.copy = this.state.copyFromSlave != '-' ? this.state.copyFromSlave : '';
        const callbacks = this.getGenericCallback(modalCallback);
        const data = Utils.formApiData('slaves', true, 'json', callbacks.success, callbacks.error);
        data.contentType = 'application/json';
        data.data = callData;
        Utils.performPUTcall(data, 'POST');
    }

    getButts = () => (
        <div className='butts'>
            <label className='inp-check'>
                <input type='checkbox' checked={this.state.dontClose} onChange={this.changeClosingLogic} />
                <span>Don't close after adding</span>
            </label>
            <button
                type='submit'
                className='modal-confirm'
                disabled={!this.state.enableSaving}
            >
                ADD PROBE
            </button>
        </div>
    )

    getProbeTypes() {
        const dataOptions = [
            { text: 'AMD64', id: 'amd64' },
            { text: 'ARM', id: 'arm' },
            { text: 'ARM64', id: 'arm64' },
        ];
        return (
            <div className='select-wrap architecture-dropdown'>
                <span className='left-title'> Select architecture:  </span>
                <Select2
                    className='select-box'
                    data={dataOptions}
                    onSelect={e => this.setState({ architecture: e.target.value })}
                    value={this.state.architecture}
                    options={{ minimumResultsForSearch: -1 }}
                    id='archInput'
                />
                <a
                    className='fa fa-download'
                    rel='noopener'
                    href={`https://updates.cocopacket.cloud/cocopacket-slave/${this.state.version}/linux-${this.state.architecture}.gz`}
                    download={`coco-${this.state.version}linux${this.state.architecture}.gz`}
                />
            </div>
        );
    }

    getAvailableSlavesForCopyAction() {
        const { slaveList } = this.props;
        const dataOptions = slaveList.map(slave => ({ text: slave.toUpperCase(), id: slave }));
        dataOptions.push({ text: '---', id: '-' });
        return (
            <div className='select-wrap copy-input-box'>
                <span className='top-title'>
                    Copy from: (Add your new probe to all IPs monitored by the selected probe bellow)
                </span>
                <Select2
                    className='select-box'
                    data={dataOptions}
                    onSelect={e => this.setState({ copyFromSlave: e.target.value })}
                    value={this.state.copyFromSlave}
                    options={{ minimumResultsForSearch: -1 }}
                    id='copyInput'
                />
            </div>
        );
    }

    render() {
        const Trigger = ({ action }) => (
            <a onClick={action} className='add-ip-button'>
                <i className='fa fa-plus' />
                Add Probe
            </a>
        );
        const styles = { content: { height: 'auto' } };
        const instructionMess = this.state.howToHidden ? 'INSTRUCTIONS TO INSTALL PRIVATE PROBE' : 'HIDE INSTRUCTIONS';
        const renderComponent = (confirmCallback, showLoading) => (
            <>
            <form id='add-new-slave-form' onSubmit={e => this.saveChanges(e, confirmCallback, showLoading)}>
                <h2>Add new probe</h2>
                <div className='input-box'>
                    <span className='add-ip-address-box'>
                        <label className='title-color label-title'>IP address<i className="fa fa-star"/></label>
                        <input
                            type='text'
                            className='input-ip'
                            required
                            pattern='^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])( *)$'
                            ref='inputIP'
                            onChange={this.onInputIpChange}
                            value={this.state.inputIP}
                            autoFocus
                        />
                    </span>
                    <span className='add-ip-port-box'>
                        <label className='title-color label-title'>Port<i className="fa fa-star"/></label>
                        <input
                            type='text'
                            className='input-port'
                            pattern='[0-9]{1,5}'
                            ref='inputPort'
                            required
                            onChange={this.onInputPortChange}
                            value={this.state.inputPort}
                        />
                    </span>
                    <span className='add-ip-alias-box'>
                        <label className='title-color label-title'>Alias<i className="fa fa-star"/></label>
                        <input
                            type='text'
                            className='input-alias'
                            ref='inputAlias'
                            onChange={this.onInputAliasChange}
                            value={this.state.inputAlias}
                        />
                    </span>
                </div>
                {this.getAvailableSlavesForCopyAction()}
                {this.getButts()}
            </form>
            <div className='howto-box'>
                <a className='instruction-trigger' onClick={this.showHideInstructions}>
                    {instructionMess}
                    <i className={`fa fa-angle-${this.state.howToHidden ? 'down' : 'up'}`} />
                </a>
                <div className={`howto ${this.state.howToHidden ? 'hidden' : ''}`}>
                    <ul className='list'>
                        <li>
                            <span>
                                1) Download the latest probe version (version:
                                { this.state.version }
                                )
                            </span>
                            {this.getProbeTypes()}
                            <span className='left-title'>
                                *To install your probe in docker container look
                                <a
                                    href='https://github.com/kanocz/cocopacket-go-api#running-cocopacket-slave-from-docker'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='link'
                                >
                                    {' '+'here'}
                                </a>
                            </span>
                        </li>
                        <li>
                            2) Download an initial probe config file
                            <a
                                className='fa fa-download'
                                download='probeConfig.json'
                                href='/v1/status/slaveconfig'
                            />
                        </li>
                        <li>
                            3) Use a supervisor to create a deamon for the probe (download an
                            instance
                            for systemd
                            <a
                                className='fa fa-download'
                                href='/examples/probeRun.txt'
                                download='cocoProbe.service'
                            />
                            )
                        </li>
                        <li>4) Connect to your probe through the form in this modal window</li>
                        <li>
                            * Detailed installation description
                            <a
                                className='fa fa-download'
                                href='/examples/addProbeReadme.txt'
                                download='probe_install_howto.txt'
                            />
                        </li>
                    </ul>
                </div>
            </div>
            </>
        );
        return (
            <ModalWrapper
                Trigger={Trigger}
                style={styles}
                modalID='modal-add-slave'
                redirect={this.successRedirect}
                openModal={this.openModal}
                dontClose={this.state.dontClose}
                render={renderComponent}
                showRequiredInfo={true}
            />
        );
    }

}

export default AddSlaveModal;
