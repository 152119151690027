import React from 'react';
import Utils from '../../js/utils.js';

class OperationConfirm extends React.Component {
    constructor(props) {
        super(props);
        // Set state migrating from es5 by using previous getInitialState
        this.state = { timeHide: false };
    }

    componentDidUpdate() {
        const scope = this;
        if (this.props.show && !this.state.timeHide) {
            const timeout = this.props.isSuccess ? 1500 : 20000;
            setTimeout(() => {
                if (scope.mounted) {
                    scope.setState({ timeHide: true });
                    scope.props.redirect();
                }
            }, timeout);
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.show && !nextProps.show) {
            this.setState({ timeHide: false });
        }
        return this.props != nextProps || this.state != nextState;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        if (this.props.show && !this.state.timeHide) {
            return (
                <div className={'confirm-dialog' + (this.props.isSuccess ? ' success' : ' error')}>
                    { this.props.isSuccess && (
                        <h2>
                            Operation was successful
                            <i className='fa fa-check-circle' />
                        </h2>
                    )}
                    { !this.props.isSuccess && (
                        <>
                            <h2>
                                Operation was not successful
                                <i className='fa fa-times-circle' />
                            </h2>
                            <>
                                {Utils.isSet(this.props.errorPrompt) &&
                                    <span className="errPrompt">
                                        error: { this.props.errorPrompt }
                                    </span>
                                }
                                <button type='button' onClick={this.props.redirect} className='modal-confirm'>close</button>
                            </>
                        </>
                    )}
                </div>
            );
        }
        return null;
    }
}

export default OperationConfirm;
