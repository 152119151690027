/* global $:true */

import React from 'react';
import Modal from 'react-modal';
import Clipboard from 'clipboard';
import Select2 from 'react-select2-wrapper';
import Utils from '../../js/utils.js';

class PublicGraphUrl extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getInitialState = this.getInitialState.bind(this);
        this.getKey = this.getKey.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getSettingsContent = this.getSettingsContent.bind(this);
        this.spanLinkTypeClick = this.spanLinkTypeClick.bind(this);
        this.onChangeHandlerLinkType = this.onChangeHandlerLinkType.bind(this);
        this.spanGraphTypeClick = this.spanGraphTypeClick.bind(this);
        this.onChangeHandlerGraphType = this.onChangeHandlerGraphType.bind(this);
        this.spanGraphInfoClick = this.spanGraphInfoClick.bind(this);
        this.onChangeHandlerGraphInfo = this.onChangeHandlerGraphInfo.bind(this);
        this.changeUrl = this.changeUrl.bind(this);
        this.changeState = this.changeState.bind(this);
        this.probeChangeSelect = this.probeChangeSelect.bind(this);
        this.changeEpoch = this.changeEpoch.bind(this);
        this.changeColor = this.changeColor.bind(this);
        this.changeRatio = this.changeRatio.bind(this);
        this.displayAdditional = this.displayAdditional.bind(this);
        this.setGraphType = this.setGraphType.bind(this);
        this.setGraphInfo = this.setGraphInfo.bind(this);
        this.generate = this.generate.bind(this);
        this.generatePrint = this.generatePrint.bind(this);
        this.saveClick = this.saveClick.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        const probe = this.props.ipDetails.probe.split('=')[1];
        return {
            modalIsOpen: false,
            displayAdditionalInfo: false,
            activeTab: 'URL',
            graphType: this.props.graphType,
            graphInfo: true,

            key: '',
            selectedProbe: probe,
            epoch: Number(this.props.ipDetails.epoch),
            color: '',
            ratio: 1,
            url: this.props.generalSettings.public_url,
        };
    }

    getKey() {
        // ----------------- API CALL GET -----------------
        const scope = this;
        const url = `/linkkey/${this.props.selectedIP}`;
        const successCallback = function (data) {
            const initState = scope.getInitialState();
            initState.modalIsOpen = true;
            initState.key = data.key;
            scope.setState(initState);
        };
        const data = Utils.formApiData(url, true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    openModal() {
        this.getKey();
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    getStyles() {
        const config = JSON.parse(JSON.stringify(Utils.modals.config));
        config.content.maxWidth = '1000px';
        return config;
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    getIframe(ratio, url) {
        const styleIframe = `-moz-transform: scale(${ratio},${ratio});`
            + `-webkit-transform: scale(${ratio},${ratio});`
            + `-o-transform: scale(${ratio},${ratio});`
            + `-ms-transform: scale(${ratio},${ratio});`
            + `transform: scale(${ratio},${ratio});`
            + '-moz-transform-origin: top left;'
            + '-webkit-transform-origin: top left;'
            + '-o-transform-origin: top left;'
            + '-ms-transform-origin: top left;'
            + 'transform-origin: top left;'
            + 'position: absolute;'
            + 'top: 0;'
            + 'left: 0;'
            + 'height: 600px;'
            + 'width: 100%;'
            + 'border: none;';
        let jsxs = '<div style="width: 600px; height: 600px; padding: 0; overflow: hidden;" >';
        jsxs += `<iframe src="${url}" style="${styleIframe}"/> </div>`;
        return jsxs.toLowerCase();
    }

    getSettingsContent() {
        let iframe;
        if (this.state.activeTab == 'IFRAME') {
            iframe = (
                <div className='line high'>
                    <div className='title'>
                        <span> Size ratio (%) </span>
                    </div>
                    <div className='set-option'>
                        <input
                            ref='iframeRatio'
                            type='text'
                            name='ratio'
                            placeholder='%'
                            value={this.state.ratio * 100}
                            onChange={this.changeRatio}
                        />
                    </div>
                </div>
            );
        }
        if (this.state.displayAdditionalInfo) {
            const data = this.props.probesList;
            const dataOptions = [];
            // OPTIONS
            const isURL = this.state.activeTab == 'URL';
            const isSmoke = this.state.graphType == 'advanced';
            const isGraphInfo = this.state.graphInfo;

            for (let probe in data) {
                probe = probe.toUpperCase();
                dataOptions.push(
                    { text: data[probe], id: data[probe] },
                );
            }
            return (
                <div className='rest'>
                    <div className='line'>
                        <div className='title'>
                            <span>share via</span>
                        </div>
                        <div className='set-option'>
                            <div className='switch'>
                                <span className={isURL ? 'active' : ''} data-value='url' onClick={this.spanLinkTypeClick}>URL</span>
                                <label className='switch-slider'>
                                    <input type='checkbox' onChange={this.onChangeHandlerLinkType} checked={!isURL} />
                                    <span className='slider round' />
                                </label>
                                <span className={isURL ? '' : 'active'} data-value='iframe' onClick={this.spanLinkTypeClick}>IFRAME</span>
                            </div>
                        </div>
                    </div>
                    <div className='line high'>
                        <div className='title'>
                            <span> graph time range (hours)</span>
                        </div>
                        <div className='set-option'>
                            <input ref='epochInput' type='text' name='epoch' value={this.state.epoch / 3600} onChange={this.changeEpoch} />
                        </div>
                    </div>
                    <div className='line'>
                        <div className='title'>
                            <span>graph type</span>
                        </div>
                        <div className='set-option'>
                            <div className='switch'>
                                <span className={isSmoke ? '' : 'active'} data-value='basic' onClick={this.spanGraphTypeClick}>Basic</span>
                                <label className='switch-slider'>
                                    <input type='checkbox' onChange={this.onChangeHandlerGraphType} checked={isSmoke} />
                                    <span className='slider round' />
                                </label>
                                <span className={isSmoke ? 'active' : ''} data-value='advanced' onClick={this.spanGraphTypeClick}>Smoke</span>
                            </div>
                        </div>
                    </div>
                    <div className='line high'>
                        <div className='title'>
                            <span>select slave</span>
                        </div>
                        <div className='set-option'>
                            <Select2
                                className='probes-select'
                                data={dataOptions}
                                options={{ minimumResultsForSearch: 'Infinity' }}
                                onSelect={this.probeChangeSelect}
                                value={this.state.selectedProbe}
                            />
                        </div>
                    </div>
                    <div className='line'>
                        <div className='title'>
                            <span>graph info</span>
                        </div>
                        <div className='set-option'>
                            <div className='switch'>
                                <span className={isGraphInfo ? 'active' : ''} data-value='info' onClick={this.spanGraphInfoClick}>Info</span>
                                <label className='switch-slider'>
                                    <input type='checkbox' onChange={this.onChangeHandlerGraphInfo} checked={!isGraphInfo} />
                                    <span className='slider round' />
                                </label>
                                <span className={isGraphInfo ? '' : 'active'} data-value='noinfo' onClick={this.spanGraphInfoClick}>No-Info</span>
                            </div>
                        </div>
                    </div>
                    {iframe}
                </div>
            );
        }
    }

    spanLinkTypeClick(e) {
        const type = e.target.getAttribute('data-value');
        this.changeState('activeTab', type);
    }

    onChangeHandlerLinkType() {
        const type = this.state.activeTab == 'URL' ? 'IFRAME' : 'URL';
        this.changeState('activeTab', type);
    }

    spanGraphTypeClick(e) {
        const type = e.target.getAttribute('data-value');
        this.changeState('graphType', type);
    }

    onChangeHandlerGraphType() {
        const type = this.state.graphType == 'basic' ? 'advanced' : 'basic';
        this.changeState('graphType', type);
    }

    spanGraphInfoClick(e) {
        const type = e.target.getAttribute('data-value');
        this.changeState('graphInfo', type == 'info');
    }

    onChangeHandlerGraphInfo() {
        const type = !this.state.graphInfo;
        this.changeState('graphInfo', type);
    }

    changeUrl(e) {
        const url = e.target.value;
        this.changeState('url', url);
    }

    changeState(key, value) {
        this.setState({
            [key]: value,
        });
    }

    probeChangeSelect(e) {
        this.changeState('selectedProbe', e.target.value);
    }

    changeEpoch(e) {
        let epoch = e.target.value;
        epoch = epoch == undefined ? 86400 : epoch * 3600;
        this.setState({
            epoch,
        });
    }

    changeColor(e) {
        const color = e.target.value;
        this.setState({
            color,
        });
    }

    changeRatio(e) {
        let ratio = e.target.value / 100;
        ratio = ratio == 0 ? 1 : ratio;
        this.setState({
            ratio,
        });
    }

    displayAdditional(e) {
        this.setState(prevState => ({ displayAdditionalInfo: !prevState.displayAdditionalInfo }));
    }

    setGraphType(e) {
        const val = e.target.getAttribute('value');
        this.setState({
            graphType: val,
        });
    }

    setGraphInfo(e) {
        const val = e.target.getAttribute('value');
        this.setState({
            graphInfo: val,
        });
    }

    generate() {
        let { url } = this.state;
        url += `/#/ip=${this.state.key}`;
        url += `&type=${this.state.graphType}`;
        url += `&epoch=${this.state.epoch}`;
        if (this.props.ipDetails.customEpoch) {
            url += `&end=${this.props.ipDetails.endPoint}`;
        }
        url += `&slave=${this.state.selectedProbe}`;
        if (this.state.activeTab == 'IFRAME') {
            url += '&iframe=yes';
        } else {
            url += '&info=';
            url += this.state.graphInfo ? 'info' : 'noinfo';
        }
        if (this.state.displayAdditionalInfo) {
            const { color } = this.state;
            if (Utils.isSet(color)) {
                url += `&color=${this.state.color.replace('#', '')}`;
            }
        }
        url = encodeURI(url);
        let out;
        if (this.state.activeTab == 'IFRAME') {
            out = this.getIframe(this.state.ratio, url);
        } else {
            out = url.toLowerCase();
        }
        return out;
    }

    generatePrint() {
        let { url } = this.state;
        url += '/*key*';
        url += `&type=${this.state.graphType}`;
        url += `&epoch=${this.state.epoch}`;
        if (this.props.ipDetails.customEpoch) {
            url += `&end=${this.props.ipDetails.endPoint}`;
        }
        url += `&slave=${this.state.selectedProbe}`;
        if (this.state.activeTab == 'IFRAME') {
            url += '&iframe=yes';
        } else {
            url += '&info=';
            url += this.state.graphInfo ? 'info' : 'noinfo';
        }
        if (this.state.displayAdditionalInfo) {
            const { color } = this.state;
            if (Utils.isSet(color)) {
                url += `&color=${this.state.color.replace('#', '')}`;
            }
        }
        url = encodeURI(url);
        if (this.state.activeTab == 'IFRAME') {
            return this.getIframe(this.state.ratio, url);
        }
        return url;
    }

    saveClick(e) {
        e.preventDefault();
        $(this.refs.confirmButton).text('Saved !');
        setTimeout(this.closeModal, 200);
    }

    copyEffect(e) {
        const selector = $(e.target);
        selector.css('color', '#19e880');
        selector.text('Link copied');
        setTimeout(() => {
            selector.text('Share graph');
            selector.css('color', '#ADC65C');
        }, 2000);
    }

    componentDidMount() {
        const selector = '.copy-btn-url';
        const clipboard = new Clipboard(selector, { text: this.generate });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedIP != nextProps.selectedIP) {
            this.closeModal();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state != nextState || this.props.ipDetails != nextProps.ipDetails;
    }

    render() {
        let trigger;
        if (this.props.triggeredFromMobile) {
            if (this.state.modalIsOpen) {
                trigger = (
                    <a className='cntrl-btn share-btn copy-btn-url' onClick={this.copyEffect}>
                        <i className='fa fa-share-square-o' />
                        Share graph
                    </a>
                );
            } else {
                this.openModal();
            }
            return (
                <div className='set-ip-header'>
                    {trigger}
                </div>
            );
        }
        trigger = (
            <a className='cntrl-btn share-btn' onClick={this.openModal}>
                <i className='fa fa-share-square-o' />
                Share
            </a>
        );
        return (
            <div className='set-ip-header share-ip'>
                {trigger}
                {this.state.modalIsOpen
                && (
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={this.getStyles()}
                        contentLabel='publicGraphModal'
                    >
                        <div className='modal modal-public-url'>
                            <button onClick={this.closeModal} className='modal-close' />
                            <form onSubmit={this.saveClick}>
                                <h2>{`Share IP ${this.props.selectedIP}`}</h2>
                                <h3>Create an url link to share this graph with others.</h3>
                                <div className='content'>
                                    <label className='label-title'>
                                        <span>PUBLIC SERVER</span>
                                    </label>
                                    <input ref='urlInput' type='text' value={this.state.url} onChange={this.changeUrl} autoComplete='off' />
                                    <a className='more-trigger' onClick={this.displayAdditional}>
                                        More settings
                                        <i className={`fa fa-chevron-${this.state.displayAdditionalInfo ? 'down' : 'up'}`} />
                                    </a>
                                    {this.getSettingsContent()}
                                </div>
                                <span className='keySpan'>
                                        URL :
                                    <span className='val'>{this.generatePrint()}</span>
                                </span>
                                <div className='butts'>
                                    <button type='submit' ref='confirmButton' className={'modal-confirm copy-btn ' + 'copy-btn-url'}>
                                        Save URL to clipboard
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal>
                )
                }
            </div>
        );
    }
}

export default PublicGraphUrl;
