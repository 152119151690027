import React from 'react';
import Utils from '../../js/utils.js';
import AddSlaveModal from '../modals/addSlave.modal.jsx';
import ModalChangeName from '../modals/probeName.modal.jsx'

class SettingsStatus extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.loadData = this.loadData.bind(this);
        this.getDisplayData = this.getDisplayData.bind(this);
        this.deleteProbe = this.deleteProbe.bind(this);
        this.removeProbeFromConfig = this.removeProbeFromConfig.bind(this);
        this.filterData = this.filterData.bind(this);
        this.filterData = this.filterData.bind(this);
        this.getTimeDifference = this.getTimeDifference.bind(this);
        this.searchOnChange = this.searchOnChange.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = { data: {}, filteredData: {}, deleteProbeKey: '' };
    }

    loadData() {
        // ----------------- API CALL GET -----------------
        const scope = this;
        const successCallback = function (data) {
            scope.setState({
                data,
                filteredData: data,
            });
        };
        const data = Utils.formApiData('/status/slaves', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    getDisplayData() {
        const records = [];
        const data = this.state.filteredData;
        if (Utils.isNotSet(data)) {
            records.push((
                <tr key={0}>
                    <td colSpan='8' style={{ textAlign: 'center' }}>
                        NO PROBES
                    </td>
                </tr>
            ));
        } else {
            let backgClass = 'dark';
            for (const slave in data) {
                backgClass = backgClass == 'light' ? 'dark' : 'light';
                const slaveDelete = this.state.deleteProbeKey == slave;
                const temp = data[slave];
                const isConnected = temp.status == 'connected';
                const diff = this.getTimeDifference(temp.last);
                const color = isConnected ? diff.sec > 30 || diff.sec == 0 ? '#F7B430' : 'green' : 'red';
                let icon = (
                    <td className='icon first'>
                        <svg>
                            <circle cx='6' cy='6' r='6' style={{ fill: color }} />
                        </svg>
                    </td>
                );
                if (slaveDelete) icon = (<td className='icon first'><i className='fa fa-times' /></td>);
                records.push((
                    <tr key={slave} className={backgClass}>
                        {icon}
                        <td>
                            <ModalChangeName name={slave} loadSlaveList={this.props.loadSlaveList} />
                        </td>
                        <td>{slave}</td>
                        <td className='status-host'>{temp.host}</td>
                        <td className='status-status'>{temp.status}</td>
                        <td className='status-version'>{temp.version}</td>
                        <td>
                            {isConnected ? diff.str : ''}
                            {isConnected && <span> ago </span>}
                        </td>
                        <td className='text-center last hover-action' onClick={() => this.deleteProbe(slave)}>
                            <i className='fa fa-trash' />
                        </td>
                    </tr>
                ));
                if (slaveDelete) {
                    if(!this.props.isRoot){
                        records.push((
                            <tr key={`${slave}del`} className='red-confirm'>
                                <td className='icon first' colSpan='6'>
                                <span>
                                    {`This operation can only be executed by administrators.`}
                                </span>
                                </td>
                                <td colSpan='2' className='last action hover-action' onClick={() => this.deleteProbe(slave)}>Close</td>
                            </tr>
                        ));
                    } else{
                        records.push((
                            <tr key={`${slave}del`} className='red-confirm'>
                                <td className='icon first' colSpan='6'>
                                <span>
                                    {`Do you want to delete ${slave} probe ?`}
                                </span>
                                </td>
                                <td className='action hover-action' onClick={() => this.removeProbeFromConfig(slave)}>YES</td>
                                <td className='last action hover-action' onClick={() => this.deleteProbe(slave)}>NO</td>
                            </tr>
                        ));
                    }
                }
            }
        }
        return records;
    }


    deleteProbe(key) {
        const stateKey = this.state.deleteProbeKey;
        const newKey = key == stateKey ? '' : key;
        this.setState({ deleteProbeKey: newKey });
    }

    removeProbeFromConfig(slave) {
        //* DELETE* `/v1/slaves` a v `?slave=` das jmeno sondy
        const scope = this;
        const successCallback = () => {
            const data = JSON.parse(JSON.stringify(scope.state.data));
            const filteredData = JSON.parse(JSON.stringify(scope.state.filteredData));
            delete data[slave];
            delete filteredData[slave];
            scope.setState({
                data,
                filteredData,
                deleteProbeKey: '',
            });
        };
        const data = Utils.formApiData(`/slaves?slave=${slave}`, true, 'json', successCallback);
        Utils.performGETcall(data, 'DELETE');
    }

    filterData(val) {
        let filteredData = {};
        if (val.length == 0) {
            filteredData = this.state.data;
        } else {
            const { data } = this.state;
            val = val.toUpperCase();
            for (let key in data) {
                key = key.toUpperCase();
                if (key.includes(val)) {
                    filteredData[key] = data[key];
                }
            }
        }
        this.setState({
            filteredData,
        });
    }

    getTimeDifference(timestamp) {
        const start = new Date(Date.parse(timestamp));
        const end = new Date();
        const diff = Math.ceil((end - start) / 1000).toFixed(0); // difference in seconds, whole digits, math-ceiled
        if (diff <= 0 || Utils.isNotSet(diff)) {
            return { sec: 0, str: '---' };
        }
        if (diff < 60) {
            return { sec: diff, str: `${diff} sec` };
        }
        const date = new Date(null); // this shows the time difference
        date.setSeconds(diff);
        return { sec: diff, str: date.toISOString().substring(11, 19) };
    }

    searchOnChange(e) {
        const val = e.target.value;
        this.filterData(val);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.slaveList != nextProps.slaveList) {
            this.loadData();
        }
        return true;
    }

    componentDidMount() {
        if (this.props.setHash) {
            Utils.setHashSilently('status');
        }
        this.loadData();
    }

    render() {
        return (
            <div className='tab-content status'>
                <div className='top-head-line'>
                    <span className='page-title'>
                        {` ${this.props.name} `}
                    </span>
                    {this.props.isRoot && <AddSlaveModal {...this.props} slaveList={Object.keys(this.state.data)} />}
                </div>
                <div className='search-wrap'>
                    <i className='fa fa-search' />
                    <input
                        className='status-search'
                        type='text'
                        placeholder='SEARCH SLAVE'
                        onChange={this.searchOnChange}
                        autoFocus
                    />
                </div>
                <div className='status-legend'>
                    <div className='color-legend'>
                        <svg>
                            <circle cx='6' cy='6' r='6' style={{ fill: 'green' }} />
                        </svg>
                        <span className='mess'>Active</span>
                    </div>
                    <div className='color-legend'>
                        <svg>
                            <circle cx='6' cy='6' r='6' style={{ fill: '#F7B430' }} />
                        </svg>
                        <span className='mess'> Iddle</span>
                    </div>
                    <div className='color-legend'>
                        <svg>
                            <circle cx='6' cy='6' r='6' style={{ fill: 'red' }} />
                        </svg>
                        <span className='mess'>Disconnected</span>
                    </div>
                </div>
                <div className='status-table settings-table'>
                    <table>
                        <thead>
                            <tr>
                                <th data-name='icon' className="text-center w-25">#</th>
                                <th data-name='icon' className="text-center w-25">#</th>
                                <th data-name='slave'>SLAVE</th>
                                <th data-name='host'>HOST</th>
                                <th data-name='Status'>STATUS</th>
                                <th data-name='Version'>VERSION</th>
                                <th data-name='activity'>LAST ACTIVITY</th>
                                <th data-name='Last ' className='text-center'>REMOVE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getDisplayData()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default SettingsStatus;
