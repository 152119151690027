/* global $:true */
import React from 'react';
import Utils from '../../js/utils.js';

class SettingsGlobal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPass: '',
            newPass: '',
            newPassConfirm: '',
            prompt: {}
        };
    }

    getPasswordForm = () => {
        const passMatch = this.state.newPass == this.state.newPassConfirm;
        let styleBorder = {};
        if (Utils.isSet(this.state.newPass) || Utils.isSet(this.state.newPassConfirm)) {
            styleBorder = (passMatch) ? { border: 'solid 2px green' } : { border: 'solid 2px red' };
        }
        return (
            <div className='reset-pass'>
                <form id='reset-pass-form' onSubmit={this.changePassword}>
                    <div className='form-content-wrap'>
                        <div className='input-wrap'>
                            <label htmlFor='oldpasswd' className='label-title'><span>OLD PASSWORD:</span></label>
                            <input
                                id='oldpasswd'
                                name='oldpasswd'
                                type='password'
                                value={this.state.oldPass}
                                onChange={e => this.inputChange(e, 'oldPass')}
                                className='long-input'
                            />
                        </div>
                        <div className='input-wrap'>
                            <label className='label-title'><span>NEW PASSWORD:</span></label>
                            <input
                                type='password'
                                style={styleBorder}
                                value={this.state.newPass}
                                onChange={e => this.inputChange(e, 'newPass')}
                                className='long-input'
                            />
                        </div>
                        <div className='input-wrap'>
                            <label htmlFor='newpasswd' className='label-title'>
                                <span>CONFIRM PASSWORD:</span>
                            </label>
                            <input
                                id='newpasswd'
                                name='newpasswd'
                                type='password'
                                style={styleBorder}
                                value={this.state.newPassConfirm}
                                onChange={e => this.inputChange(e, 'newPassConfirm')}
                                className='long-input'
                            />
                        </div>
                    </div>
                    <div className='butts mt-20'>
                        <button type='submit' className='butt-confirm' disabled={!passMatch}>
                            Change Password
                        </button>
                    </div>
                </form>
            </div>
        );
    }

    changePassword = (e) => {
        e.preventDefault();
        let params = '?oldpasswd=' + this.state.oldPass;
        params += '&newpasswd=' + this.state.newPassConfirm;
        const url = '/users/passwd' + params;
        const succMsg = 'Password change was successful';
        const errMsg =  'Sorry, password change was not successful';
        const successCallback = (succ) => {
            this.setState({
                oldPass: '',
                newPass: '',
                newPassConfirm: '',
                prompt: { isSuccess: true, msg: succMsg }
            }); };
        const errorCallback   = (err)  => { this.setState({ prompt: { isSuccess: false, msg: errMsg } }); };
        const data = Utils.formApiData(url, true, 'json', successCallback, errorCallback);
        Utils.performPUTcall(data);
    }

    inputChange = (e, key) => {
        this.setState({ [key]: e.target.value });
    }

    render() {
        return (
            <div className='tab-content'>
                {Utils.isSet(this.state.prompt) &&
                <div className={'banner-wrap__banner banner-wrap__banner--' + (this.state.prompt.isSuccess ? 'success' : 'error')}>
                    <div className='banner-wrap__text-block'>
                        <span>{this.state.prompt.msg}</span>
                    </div>
                    <button type='button' onClick={() => this.setState({ prompt: {} })} />
                </div>
                }
                <span className='page-title'>
                    {' '}
                    PASSWORD CHANGE
                    {' '}
                </span>
                {this.getPasswordForm()}
            </div>
        );
    }
}

export default SettingsGlobal;
