/* global $:true, Chart:true */
import React from 'react';
import Clipboard from 'clipboard';
import Utils from '../../../js/utils.js';

class TraceTable extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.formTextHop = this.formTextHop.bind(this);
        this.formatValue = this.formatValue.bind(this);
        this.formTextTable = this.formTextTable.bind(this);
        this.getTraceRoute = this.getTraceRoute.bind(this);
        this.getHop = this.getHop.bind(this);
        this.copyTable = this.copyTable.bind(this);
        this.getBar = this.getBar.bind(this);
    }

    formJSXHop(key, backgClass, index, data, barValue) {
        if (barValue == undefined) {
            barValue = (<td className='hop-perc' />);
        }
        return (
            <tr key={`${index}.${key}`} className={backgClass}>
                <td className='hop-order first'>{index}</td>
                <td className='hop-id'>{data.ip}</td>
                <td className='hop-as'>{data.as}</td>
                <td className='hop-host'>{data.host}</td>
                {barValue}
                <td className='hop-time'>{data.avg}</td>
                <td className='hop-time'>{data.min}</td>
                <td className='hop-time last'>{data.max}</td>
            </tr>
        );
    }

    formJSXTable(hops) {
        return (
            <div className='trace-table' key='0'>
                <table cellPadding='0' cellSpacing='0'>
                    <thead>
                        <tr>
                            <th>HOP</th>
                            <th>IP</th>
                            <th>AS</th>
                            <th>HOST</th>
                            <th>PACKET LOSS %</th>
                            <th>AVG (ms)</th>
                            <th>BEST (ms)</th>
                            <th>WRST (ms)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hops}
                    </tbody>
                </table>
            </div>
        );
    }

    formTextHop(index, data, received) {
        const { longestHost } = TraceTable;
        const hostSpace = this.getSpaces(longestHost - data.host.length);
        let loss = Number(100 - received).toFixed(1);
        loss = `${this.getSpaces(5 - loss.toString().length) + loss}%`;
        const hop = [];
        hop.push(`${this.getSpaces(2 - index.toString().length) + index}.`);
        hop.push(data.ip + this.getSpaces(16 - data.ip.toString().length));
        hop.push(data.as + this.getSpaces(5 - data.as.toString().length));
        hop.push(data.host + hostSpace);
        hop.push(loss);
        hop.push(this.formatValue(data.avg));
        hop.push(this.formatValue(data.min));
        hop.push(this.formatValue(data.max));
        return hop.join('  ');
    }

    formatValue(value, fixed = 2, length = 5) {
        if (Number.isNaN(value)) {
            value = '*';
        } else {
            value = Number(value).toFixed(fixed);
        }
        const spaces = this.getSpaces(length - value.toString().length);
        return spaces + value;
    }

    getSpaces(count) {
        return count < 0 ? '' : ' '.repeat(count);
    }

    formTextTable(hops) {
        const content = hops.join('\n');
        const { longestHost } = TraceTable;
        const space_size = this.getSpaces(longestHost);
        const date = new Date(this.props.time*1000).toString();
        const head = 'Destination IP: ' + this.props.ip + ' (AS' + this.props.as + ')' +
            '\nSource IP: ' + this.props.source + ' (AS' + this.props.sourceAS + ')' +
            '\nStart: ' + date +
            '\n--------------------------------------------------------------------------- \n';
        return `${head}HOP  IP                AS     HOST${space_size}Loss%   Avg    Best   Wrst  \n${content}`;
    }

    getTraceRoute() {
        const { data } = this.props;
        const hops = [];
        let emptyCount = 0;
        let backgroundColorClass = 'dark';
        let longestHost = 0;
        // Iterate sequentially through every HOP
        for (let index = 0; index < data.length; index++) {
            const hopRawData = data[index]; // select a hop entry
            backgroundColorClass = backgroundColorClass == 'light' ? 'dark' : 'light';
            // table lines have two different backgrounds for better read-ability
            // fill table correctly for empty lines with '*', only first 4 empty entries are recorded in the table
            if (hopRawData.length > 0) {
                emptyCount = 0;
                const hopObj = this.getHop(hopRawData, backgroundColorClass, index, 'jsx');
                longestHost = hopObj['max-length'] > longestHost ? hopObj['max-length'] : longestHost;
                hops.push(...hopObj.hop);
            } else if (emptyCount < 4) {
                emptyCount++;
                const { hop } = this.getHop([{
                    ip: '*', host: '*', received: 0, avg: '*', min: '*', max: '*', as: '*',
                }], backgroundColorClass, index, 'jsx');
                hops.push(...hop);
            }
        }
        TraceTable.longestHost = longestHost;
        return this.formJSXTable(hops);
    }

    getHop(hopInfo, backgroundColorClass, index, hopForm) {
        let count = 0;
        const singleHop = [];
        let longestHost = 0;
        let key = 0;
        // Each hop can have multiple lines and for each there is an entry in the table
        // This situation is rare, so for performance it is better to single out this case
        if (hopInfo.length > 1) {
            for (let innerIndex = 0; innerIndex < hopInfo.length; innerIndex++) {
                const hopData = hopInfo[innerIndex];
                // find the longest host.name to properly format table for copy in the clipboard function
                if (hopData.host.length > longestHost) {
                    longestHost = hopData.host.length;
                }
                count += hopData.received;
                if (hopForm == 'jsx') {
                    singleHop.push(
                        this.formJSXHop(key++, backgroundColorClass, index, hopData, (<td className='hop-perc' />)),
                    );
                } else {
                    singleHop.push(this.formTextHop(index, hopData, ''));
                }
            }
        } else {
            count = hopInfo[0].received;
        }
        if (hopForm == 'jsx') {
            const hopData = hopInfo[0];
            singleHop[0] = this.formJSXHop(key++, backgroundColorClass, index, hopData, this.getBar(count));
            if (hopData.host.length > longestHost) {
                longestHost = hopData.host.length;
            }
            return { hop: singleHop, 'max-length': longestHost };
        }
        singleHop[0] = this.formTextHop(index, hopInfo[0], count);
        return singleHop;
    }

    copyTable() {
        const { data } = this.props;
        if (Utils.isNotSet(data)) {
            'no-data';
        }
        const hops = [];
        let emptyCount = 0;
        // Iterate sequentially through every HOP
        for (let index = 0; index < data.length; index++) {
            const hopRawData = data[index]; // select a hop entry
            // fill table correctly for empty lines with '*', only first 4 empty entries are recorded in the table
            if (hopRawData.length > 0) {
                emptyCount = 0;
                const hop = this.getHop(hopRawData, '', index, 'text');
                hops.push(...hop);
            } else if (emptyCount < 4) {
                emptyCount++;
                const hop = this.getHop([{
                    ip: '*', host: '*', received: 0, avg: '*', min: '*', max: '*', as: '*',
                }], '', index, 'text');
                hops.push(...hop);
            }
        }
        return this.formTextTable(hops);
    }

    getBar(received) {
        const bars = [];
        let count = 10 - (received / 10);
        const colorIndex = count > 6 ? 6 : count;
        const style = { fill: Chart.colorField[colorIndex] };
        for (let i = 0; i < 10; i++) {
            if (count > 0) {
                bars.push(<svg key={i}><rect height='17px' width='4px' style={style} /></svg>);
                count -= 1;
            } else {
                bars.push(<svg key={i}>
                    <rect height='17px' width='4px' style={{ fill: 'rgba(155,157,172,0.20)' }} />
                          </svg>);
            }
        }

        return (
            <td className='hop-perc'>
                <div className='bar'>
                    {bars}
                </div>
                <span className='text-percentage'>
                    {100 - received}
%
                </span>
            </td>
        );
    }

    copyEffect(e) {
        const selector = $(e.target);
        selector.css('color', '#19e880');
        setTimeout(() => {
            selector.text('');
            selector.css('color', '#ADC65C');
        }, 2000);
    }

    componentDidMount() {
        const selector = '.copy-btn-trace-'+this.props.time;
        const clipboard = new Clipboard(selector, { text: this.copyTable });
    }

    render() {
        const time = new Date(this.props.time * 1000);
        const leadingZero = time.getMinutes() < 10 ? '0' : '';
        const timeStr = `${time.getDate()}.${time.getMonth() + 1} 
                         ${time.getHours()}:${leadingZero}${time.getMinutes()}`;
        return (
            <div className='trace-data-record'>
                <div className='trace-table-top'>
                    <span className='time'>{timeStr}</span>
                    <a className={'fa fa-copy copy-btn-trace-'+this.props.time} onClick={this.copyEffect} />
                </div>
                {this.getTraceRoute()}
            </div>
        );
    }
}

export default TraceTable;
