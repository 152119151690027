import React from 'react';
import Utils from '../../js/utils.js';

class SettingsVersion extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => (
        {
            data: {},
            version: '',
            allCount: this.getOverAllCount(this.props.config),
            buttonStateMaster: 'Update master',
            buttonStateProbes: 'Update all probes',
        }
    )

    loadData = () => {
    // ----------------- API CALL GET -----------------
        const scope = this;
        const successCallback = function (data) {
            scope.setState({
                version: data.version,
                data: data.license,
            });
        };
        const data = Utils.formApiData('/status/version', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    getValue = (data, key) => {
        if (Utils.isSet(data)) {
            return data[key];
        }
        return '';
    }

    getOverAllCount = (data) => {
        let count = 0;
        for (const key in data) {
            count += data[key].slaves.length;
        }
        return count;
    }

    getValidDate = () => {
        const data = this.state.data.valid;
        if (Utils.isSet(data)) {
            const date = new Date(data);
            return `${date.getDate()}.${Utils.getMonthName(date.getMonth(), false)}.${date.getFullYear()}`;
        }
        return '';
    }

    updateBackendMaster = () => {
        this.setState({ buttonStateMaster: 'Updating...' })
        const successCallback = (succ) => {
            let msg = 'Update from [' + succ.old + '] to [' + succ.new + '] was successful.';
            this.setState({ prompt: { isSuccess: true, msg }, buttonStateMaster: 'Update master' });
            this.loadData();
        };
        const errorCallback = (err) => {
            let msg = 'Sorry, updating master was not successful (' + err.error + ').'
            this.setState({ prompt: { isSuccess: false, msg }, buttonStateMaster: 'Update master' }); };
        const data = Utils.formApiData('/update/master', true, 'json', successCallback, errorCallback);
        Utils.performPUTcall(data, 'POST');
    }

    updateBackendProbes = () => {
        this.setState({ buttonStateProbes: 'Updating...' })
        const successCallback = (succ) => {
            let msg = 'Process of update probes was successfully initialized, for more info check probes/status section.';
            this.setState({ prompt: { isSuccess: true, msg }, buttonStateProbes: 'Update all probes' });
        };
        const errorCallback = (err) => {
            let msg = 'Sorry, updating probes was not successful.'
            this.setState({ prompt: { isSuccess: false, msg }, buttonStateProbes: 'Update all probes' }); };
        const data = Utils.formApiData('/update/slaves', true, 'json', successCallback, errorCallback);
        Utils.performPUTcall(data, 'POST');
    }

    componentWillMount() {
        this.loadData();
    }

    render() {
        const { data } = this.state;
        const isNotClient = !window.location.host.includes('client'); // hard-coded fix to hide master updates for clients
        const allowUpdates = this.props.isRoot && isNotClient;
        return (
            <div className='tab-content info'>
                {Utils.isSet(this.state.prompt) &&
                <div className={'banner-wrap__banner banner-wrap__banner--' + (this.state.prompt.isSuccess ? 'success' : 'error')}>
                    <div className='banner-wrap__text-block'>
                        <span>{this.state.prompt.msg}</span>
                    </div>
                    <button type='button' onClick={() => this.setState({ prompt: {} })} />
                </div>
                }
                <span className='page-title'>
                    {' '}
                    {this.props.name}
                    {' '}
                </span>
                <span>
                    IP count:
                    <span className='val'>
                        {Utils.formatNumber(this.getValue(data.pingIP, 'used'), 0)}
                    </span>
                </span>
                <span>
                    Slave count:
                    <span className='val'>
                        {this.getValue(data.slave, 'used')}
                    </span>
                </span>
                <span>
                    IP & Slave count:
                    <span className='val'>
                        {Utils.formatNumber(this.state.allCount, 0)}
                    </span>
                </span>
                <span className='subtitle'> BACKEND </span>
                <span>
                    Master version:
                    <span className='val'>
                        {this.state.version}
                    </span>
                    {allowUpdates &&
                    <button type="button" className="trigger ml-20" onClick={this.updateBackendMaster}>
                        { this.state.buttonStateMaster }
                    </button>
                    }
                    {allowUpdates &&
                    <button type="button" className="trigger ml-20" onClick={this.updateBackendProbes}>
                        { this.state.buttonStateProbes }
                    </button>
                    }
                </span>
                <span className='subtitle'> LICENSE & LIMITS </span>
                <span>
                    Max allowed IP count:
                    <span className='val'>
                        {Utils.formatNumber(this.getValue(data.pingIP, 'soft'), 0)}
                    </span>
                </span>
                <span>
                    Max allowed slave count:
                    <span className='val'>
                        {Utils.formatNumber(this.getValue(data.slave, 'soft'), 0)}
                    </span>
                </span>
                <span>
                    Valid until:
                    <span className='val'>
                        {this.getValidDate()}
                    </span>
                </span>
            </div>
        );
    }
}

export default SettingsVersion;
