/* global $:true */
import React from 'react';
import Utils from '../../../../js/utils.js';
import PublicGraphUrl from '../../../modals/publicGraphUrl.jsx';

class InnerGraphHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            graphType: this.props.ipDetails.graphType,
            accuracy: this.props.ipDetails.accuracy,
            zoomLevel: this.props.zoomLevel,
            isUTC: this.props.isUTC
        };
    }

    changeGraphType = (type) => {
        this.setState((prev) => {
            const graphType = Utils.isNotSet(type) ? (prev.graphType === 'basic' ? 'advanced' : 'basic') : type;
            this.props.setGraphType(graphType);
            return ({ graphType });
        });
    }

    changeGraphAccuracy = (graphAccuracy) => {
        this.setState((prev) => {
            const accuracy = Utils.isNotSet(graphAccuracy) ? (prev.accuracy === 'normal' ? 'high' : 'normal') : graphAccuracy;
            this.props.setAccuracyType(accuracy);
            return ({ accuracy });
        });
    }

    changeUTC = (timeType) => {
        this.setState((prev) => {
            const isUTC = Utils.isNotSet(timeType) ? !prev.isUTC : timeType;
            this.props.setUTC(isUTC);
            return ({ isUTC });
        });
    }

    changeZoomLevel = (zoom) => {
        this.setState((prev) => {
            const zoomLevel = Utils.isNotSet(zoom) ? +!prev.zoomLevel : zoom;
            this.props.setZoom(zoomLevel);
            return ({ zoomLevel });
        });
    }


    getZoomSwitch = () => {
        const { zoomLevel } = this.state;
        return (
            <div className="range-slider">
                <span className='fa fa-minus' onClick={() => this.changeZoomLevel(0)} />
                <input
                    type='range'
                    name="points"
                    min="0"
                    max='1'
                    onChange={() => this.changeZoomLevel()}
                    value={zoomLevel}
                />
                <span className="fa fa-plus" onClick={() => this.changeZoomLevel(1)} />
            </div>
        );
    }

    getSwitches() {
        const isSmoke = this.state.graphType === 'advanced';
        const isHigh = this.state.accuracy === 'high';
        const { isUTC } = this.state;
        return (
            <div className='switches'>
                <div className='switch'>
                    <span className={isSmoke ? '' : 'active'} onClick={() => this.changeGraphType('basic')}>
                        Basic
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={() => this.changeGraphType()} checked={isSmoke} />
                        <span className='slider round' />
                    </label>
                    <span className={isSmoke ? 'active' : ''} onClick={() => this.changeGraphType('advanced')}>
                        Smoke
                    </span>
                </div>
                <div className='switch'>
                    <span className={isHigh ? '' : 'active'} onClick={() => this.changeGraphAccuracy('normal')}>
                        Normal
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={() => this.changeGraphAccuracy()} checked={isHigh} />
                        <span className='slider round' />
                    </label>
                    <span className={isHigh ? 'active' : ''} onClick={() => this.changeGraphAccuracy('high')}>
                        High
                    </span>
                </div>
                <div className='switch'>
                    <span className={isUTC ? '' : 'active'} onClick={() => this.changeUTC(false)}>
                        GMT
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={() => this.changeUTC()} checked={isUTC} />
                        <span className='slider round' />
                    </label>
                    <span className={isUTC ? 'active' : ''} onClick={() => this.changeUTC(true)}>
                        UTC
                    </span>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className='panel'>
                <div className='controls-wrap'>
                    {this.getZoomSwitch()}
                    {this.getSwitches()}
                    <PublicGraphUrl {...this.props} graphType={this.state.graphType} />
                </div>
            </div>
        );
    }
}

export default InnerGraphHeader;
