import React from 'react';
import Utils from '../../js/utils.js';

class SettingsUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: undefined,
            userAddition: false,
            deleteUser: -1,
            resetUserPass: -1,
            inputEmail: '',
            prompt: {},
        };
    }

    loadData = () => {
        // ----------------- API CALL GET -----------------
        const successCallback = (users) => {
            this.setState({
                users,
                deleteUser: -1,
                resetUserPass: -1
            });
        };
        const data = Utils.formApiData('/users', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    removeUserFromConfig = (email) => {
        const successCallback = (data) => { this.loadData(); };
        let formParams = '?login=' + email;
        const form = Utils.formApiData('users' + formParams , true, 'json', successCallback);
        Utils.performGETcall(form, 'DELETE');
    }

    addNewUserInConfig = (e) => {
        e.preventDefault();
        const email = this.state.inputEmail;
        const successCallback = () => {
            const succMsg = `Success! User ${email} was invited via email`;
            const errMsg = 'Sorry, unexpected error occurred while creating new user.';
            let callbacks = this.getGenericCallbacks(succMsg, errMsg, true);
            this.resetUserPassInConfig(email, callbacks);
        };
        let formParams = '?login=' + email + '&type=user';
        const form = Utils.formApiData('users' + formParams, true, 'json', successCallback);
        Utils.performPUTcall(form);
        this.setState({
            userAddition: false,
            inputEmail: '',
        });
    }

    resetUserPassInConfig = (email, callbacks) => {
        if (Utils.isNotSet(callbacks)) {
            const succMsg = 'Password change successful. New password was sent to user\'s email.';
            const errMsg = 'Sorry, password change was not successful';
            callbacks = this.getGenericCallbacks(succMsg, errMsg);
        }
        let formParams = '?user=' + email;
        const form = Utils.formApiData('/users/reset' + formParams, true, 'json', callbacks.success, callbacks.error);
        Utils.performGETcall(form);
        this.setState({ resetUserPass: -1 });
    }

    changeUserPermissionInConfig = (email) => {
        const { users } = this.state;
        let formParams = '?login=' + email  + '&type=' + (users[email] ? 'user' : 'admin');
        const succMsg = `Permission change for user ${email} successful`;
        const errMsg =  'Sorry, permission change was not successful';
        const callbacks = this.getGenericCallbacks(succMsg, errMsg, true);
        const form = Utils.formApiData('users' + formParams, true, 'json', callbacks.success, callbacks.error);
        Utils.performPUTcall(form);
    }

    getGenericCallbacks = (succMsg, errMsg, reload = false) => {
        let callbacks = {};
        if (Utils.isSet(succMsg)) {
            callbacks.success = () => {
                if (reload) { this.loadData(); };
                this.setState({ prompt: { isSuccess: true, msg: succMsg } });
            };
        }
        if (Utils.isSet(succMsg)) {
            callbacks.error = () => { this.setState({ prompt: { isSuccess: false, msg: errMsg } }); };
        }
        return callbacks;
    }

    getContent = () => {
        let { users } = this.state;
        let userKeys = Object.keys(users);
        let content = [];
        let backgClass = 'dark';
        userKeys.forEach((key, index) => {
            backgClass = backgClass == 'light' ? 'dark' : 'light';
            let isRead = true;
            let isWrite = users[key];
            let userDelete = this.state.deleteUser === index;
            let resetPass = this.state.resetUserPass === index;
            let lockedUser = this.props.username === key;
            let icon = (
                <td className='icon first'>
                    <svg>
                        <circle cx='6' cy='6' r='6' style={{ fill: 'green' }} />
                    </svg>
                </td>
            );
            if (userDelete) icon = (<td className='icon first'><i className='fa fa-times' /></td>);
            if (resetPass) icon = (<td className='icon first'><i className='fa fa-key' /></td>);
            if (lockedUser) icon = (<td className='icon first'><i className='fa fa-lock' /></td>);
            content.push(
                <tr key={index} className={backgClass + (lockedUser ? ' locked-row' : '')}>
                    {icon}
                    <td>{key}</td>
                    <td className="permission hover-action" onClick={() => this.changeUserPermissionInConfig(key)}>
                        <span className={`permission-wrap ${isRead ? 'active' : ''}`}>
                            {isRead && <span className="fa fa-check" />}
                            <span>Read</span>
                        </span>
                        <span className={`permission-wrap ${isWrite ? 'active' : ''}`}>
                            {isWrite && <span className="fa fa-check" />}
                            <span>Write</span>
                        </span>
                    </td>
                    <td className="password-reset hover-action" onClick={() => this.resetUsersPassword(index)}>
                        <span className="yell">Reset password</span>
                    </td>
                    <td className="delete last text-center hover-action" onClick={() => this.deleteUser(index)}>
                        <i className="fa fa-trash" />
                    </td>
                </tr>
            );
            if (userDelete) {
                content.push(
                    <tr key={`${key}del`} className='red-confirm'>
                        <td className='icon first' colSpan='3'>
                        <span>
                            {`Do you want to delete ${key} user ?`}
                        </span>
                        </td>
                        <td className='action hover-action' onClick={() => this.removeUserFromConfig(key)}>YES</td>
                        <td className='last action hover-action' onClick={() => this.deleteUser(index)}>NO</td>
                    </tr>
                );
            }
            if (resetPass) {
                content.push(
                    <tr key={`${key}del`} className='yellow-confirm'>
                        <td className='icon first' colSpan='3'>
                            <span>
                                {`Do you want to reset ${key}'s password via email ?`}
                            </span>
                        </td>
                        <td className='action hover-action' onClick={() => this.resetUserPassInConfig(key)}>YES</td>
                        <td className='last action hover-action' onClick={() => this.resetUsersPassword(index)}>NO</td>
                    </tr>
                );
            }
        });
        return (
            <div className='settings-table'>
                <table>
                    <thead>
                    <tr>
                        <th className="w-25 text-center">#</th>
                        <th>USER</th>
                        <th className="w-200">PERMISSIONS</th>
                        <th className="w-150">PASSWORD</th>
                        <th className="w-100 text-center">DELETE</th>
                    </tr>
                    </thead>
                    <tbody>
                    {content}
                    </tbody>
                </table>
            </div>
        );
    }

    deleteUser = (key) => {
        this.setState((prevState) => {
            const stateKey = prevState.deleteUser;
            const newKey = key === stateKey ? -1 : key;
            return {
                deleteUser: newKey,
                resetUserPass: -1
            };
        });
    }

    resetUsersPassword = (key) => {
        this.setState((prevState) => {
            const stateKey = prevState.resetUserPass;
            const newKey = key === stateKey ? -1 : key;
            return {
                resetUserPass: newKey,
                deleteUser: -1,
            };
        });
    }

    componentWillMount() {
        this.loadData();
    }

    render() {
        const { users } = this.state;
        return (
            <div className='tab-content users'>
                {Utils.isSet(this.state.prompt) &&
                <div className={'banner-wrap__banner banner-wrap__banner--' + (this.state.prompt.isSuccess ? 'success' : 'error')}>
                    <div className='banner-wrap__text-block'>
                        <span>{this.state.prompt.msg}</span>
                    </div>
                    <button type='button' onClick={() => this.setState({ prompt: {} })} />
                </div>
                }
                {Utils.isSet(users) &&
                <div className="status-table">
                    <div className="top">
                        <span className="page-title">
                            USERS
                            <span>{ Object.keys(users).length + ' active users' }</span>
                        </span>
                        {this.props.isRoot &&
                            <>
                                {!this.state.userAddition &&
                                <button type="button" className="trigger" onClick={() => this.setState({ userAddition: true })}>
                                    <i className="fa fa-plus" />
                                    Add User
                                </button>
                                }
                                {this.state.userAddition &&
                                <button type="button" className="trigger" onClick={() => this.setState({ userAddition: false })}>
                                    <i className="fa fa-times" />
                                    close
                                </button>
                                }
                            </>
                        }
                    </div>
                    {this.state.userAddition &&
                    <div className="add-user">
                        <div className="add-user-inner">
                            <h3>Add new user</h3>
                            <form onSubmit={this.addNewUserInConfig}>
                                <div className="input-wrap">
                                    <label htmlFor="user-email">EMAIL ADDRESS</label>
                                    <input
                                        type="email"
                                        placeholder="aaa@bbb.com"
                                        id="user-email"
                                        value={this.state.inputEmail}
                                        onChange={e => this.setState({ inputEmail: e.target.value })}
                                    />
                                    <button type="submit"> Invite </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    }
                    {this.getContent()}
                </div>
                }
            </div>
        );
    }
}

export default SettingsUsers;
