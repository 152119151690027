import React from 'react';
import Utils from '../../../js/utils.js';
import AddAlertModal from '../../modals/addAlert.modal.jsx';
import geTemplateLibrary from './alertTemplates.jsx';

/**
 * PROPS
 *
 * name - [string] - alert's alias
 * isRoot - [bool - boolean = true if user has admin privileges
 */

class SettingsAlerts extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            deleteAlert: -1,
            setForm: '',
            templateLibrary: {}
        };
    }

    loadData = () => {
        const url = '/notify';
        const successCallback = data => this.setState({ data, templateLibrary: { ...geTemplateLibrary(), ...data } });
        const data = Utils.formApiData(url, true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    editRow = (index, key) => {
        this.setState((prevState) => {
            const stateVal = prevState[key]
            const newValue = (stateVal === index) ? -1 : index;
            return { [key]: newValue };
        });
    }

    removeAlertFromConfig = (key) => {
        const list = { ...this.state.data };
        delete list[key];
        const succMsg = `Alert ${key}, deletion was successful`;
        const errMsg =  'Sorry, alert deletion was not successful';
        const callbacks = this.getGenericCallbacks(succMsg, errMsg, true);
        const data = Utils.formApiData('/notify', true, 'json', callbacks.success, callbacks.error);
        data.contentType = 'application/json';
        data.data = list;
        Utils.performPUTcall(data, 'PUT');
        this.setState({ deleteAlert: -1 });
    }

    getGenericCallbacks = (succMsg, errMsg, reload = false) => {
        let callbacks = {};
        if (Utils.isSet(succMsg)) {
            callbacks.success = () => {
                if (reload) { this.loadData(); };
                this.setState({ prompt: { isSuccess: true, msg: succMsg } });
            };
        }
        if (Utils.isSet(succMsg)) {
            callbacks.error = () => { this.setState({ prompt: { isSuccess: false, msg: errMsg } }); };
        }
        return callbacks;
    }

    updateConfig = (key, value, isEdit = true, keyToDelete = '') => {
        let list = { ...this.state.data, [key]: value };
        if (Utils.isSet(keyToDelete)) {
            delete list[keyToDelete];
        }
        let errMsg =  `Sorry, ${key} alert edit was not successful`;
        let succMsg = `Alert ${key} edit was successful`;
        if (!isEdit) {
            succMsg = `Alert ${key} adding was successful`;
            errMsg =  'Sorry, alert adding was not successful';
        }
        const callbacks = this.getGenericCallbacks(succMsg, errMsg, true);
        const data = Utils.formApiData('/notify', true, 'json', callbacks.success, callbacks.error);
        data.contentType = 'application/json';
        data.data = list;
        Utils.performPUTcall(data, 'PUT');
    }

    getContent = () => {
        const { data } = this.state;
        const notifyKeys = Object.keys(data);
        const content = [];
        let backgClass = 'dark';
        notifyKeys.forEach((key, index) => {
            backgClass = backgClass == 'light' ? 'dark' : 'light';
            const local = data[key];
            const alertDelete = (index === this.state.deleteAlert);
            let icon = (<td className='icon first'><i className='fa fa-bell-o' /></td>)
            if (alertDelete) icon = (<td className='icon first'><i className='fa fa-times' /></td>);
            content.push(
                <tr key={index} className={backgClass}>
                    {icon}
                    <td>{key}</td>
                    <td>{local.url}</td>
                    <td className='icon text-center hover-action'>
                        <AddAlertModal
                            templateLibrary={this.state.templateLibrary}
                            loadSmallTrigger={true}
                            data={this.state.templateLibrary[key]}
                            alias={key}
                            updateAlerts={this.updateConfig}
                            isEdit={true}
                        />
                    </td>
                    <td className='icon text-center last hover-action' onClick={() => this.editRow(index, 'deleteAlert')}>
                        <i className='fa fa-trash' />
                    </td>
                </tr>
            );
            if (alertDelete) {
                content.push(
                    <tr key={`${key}del`} className="red-confirm">
                        <td className='icon first' colSpan='3'>
                        <span>
                            {`Do you want to delete ${key} alert ?`}
                        </span>
                        </td>
                        <td className='action hover-action' onClick={() => this.removeAlertFromConfig(key)}>YES</td>
                        <td className='last action hover-action' onClick={() => this.editRow(index,'deleteAlert')}>NO</td>
                    </tr>
                );
            }
        });
        if (content.length === 0) {
            content.push(
                <tr key={'no-data'}>
                    <td className='first last' colSpan='4'>
                        <span> no alerts </span>
                    </td>
                </tr>
            );
        }
        return (
            <div className="settings-table">
                <table>
                    <thead>
                        <tr>
                            <th className="w-25 text-center">#</th>
                            <th className="w-100">ALIAS</th>
                            <th>URL</th>
                            <th className="w-50">EDIT</th>
                            <th className="w-50">DELETE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {content}
                    </tbody>
                </table>
            </div>
        );
    }

    componentWillMount() {
        this.loadData();
    }

    render() {
        return (
            <div className='tab-content alerts'>
                {Utils.isSet(this.state.prompt) &&
                <div className={'banner-wrap__banner banner-wrap__banner--' + (this.state.prompt.isSuccess ? 'success' : 'error')}>
                    <div className='banner-wrap__text-block'>
                        <span>{this.state.prompt.msg}</span>
                    </div>
                    <button type='button' onClick={() => this.setState({ prompt: {} })} />
                </div>
                }
                <div className='top-head-line'>
                    <span className='page-title'>
                        {` ${this.props.name} `}
                    </span>
                    {this.props.isRoot &&
                    <AddAlertModal updateAlerts={this.updateConfig} templateLibrary={this.state.templateLibrary} isEdit={false}/>
                    }
                </div>
                <div className='content-wrap'>
                    <div>{this.getContent()}</div>
                </div>
            </div>
        );
    }
}

export default SettingsAlerts;
