/* global $:true */
import React from 'react';
import ReactDOM from 'react-dom';
import crossroads from 'crossroads';
import hasher from 'hasher';
import Main from '../react-js/main.jsx';
import Error from '../react-js/error/404Error.jsx';
import Utils from './utils.js';
import 'signals';

const printRoutes = false;
const Route = {
    init() {
        Route.isMobile = window.innerWidth <= 800 && window.innerHeight <= 900;
        const call = Utils.formApiData('/status/user', true, 'json', this.route);
        Utils.performGETcall(call, 'GET', false);
    },

    route(userInfo) {
        const bindRoutes = true; // optional logic
        const auth = userInfo.authorized;
        const user = userInfo.isAdmin ? 'root' : 'user';
        const username = userInfo.loggedAs;
        const { IPAddAllowed } = userInfo;
        const crossroadInstance = crossroads.create();
        crossroadInstance.greedyEnabled = false;
        // --------------------------  ADD ROUTE --------------------------
        if (bindRoutes) {
            const container = document.getElementById('routeContent');
            // --------------------------------------- ROUTE 0 ------------------------------------------------
            let route0 = crossroadInstance.addRoute(
                'detail',
                () => {
                    ReactDOM.render(
                        <Main
                            view='detail'
                            isMobile={Route.isMobile}
                            user={user}
                            username={username}
                            isLogged={auth}
                            IPAddAllowed={IPAddAllowed}
                        />,
                        container
                    );
                    crossroadInstance.resetState();
                }
            );
            route0.matched.add(() => { if (printRoutes) console.log('#0 -- detail '); });

            // --------------------------------------- ROUTE 1 ------------------------------------------------
            let route1 = crossroadInstance.addRoute(
                'detail?ip={ip}&end={point}&epoch={epoch}&probe={probe}&graphType={type}',
                (ip, point, epoch, probe, type) => {
                    if (Number(point) <= Number(epoch)) {
                        ReactDOM.render(<Error />, document.getElementById('routeContent'));
                    } else {
                        const info = {
                            endPoint: point, epoch, probe: `slave=${probe}`, graphType: type,
                        };
                        ReactDOM.render(
                            <Main
                                ip={ip}
                                view='detail'
                                graph={info}
                                isMobile={Route.isMobile}
                                user={user}
                                username={username}
                                isLogged={auth}
                                IPAddAllowed={IPAddAllowed}
                            />,
                            container
                        );
                    }
                    crossroadInstance.resetState();
                }
            );
            route1.matched.add(() => {
                if (printRoutes) {
                    console.log('#1 - detail?ip={ip}&end={point}&epoch={epoch}&probe={probe}&graphType={type}');
                }
            });

            // --------------------------------------- ROUTE 2 ------------------------------------------------
            let route2 = crossroadInstance.addRoute(
                'detail?ip={ip}&epoch={epoch}&probe={probe}&graphType={type}',
                (ip, epoch, probe, type) => {
                    const end = Utils.getTimeNow();
                    const info = {
                        endPoint: end, epoch, probe: `slave=${probe}`, graphType: type,
                    };
                    ReactDOM.render(
                        <Main
                            ip={ip}
                            view='detail'
                            graph={info}
                            isMobile={Route.isMobile}
                            user={user}
                            username={username}
                            isLogged={auth}
                            IPAddAllowed={IPAddAllowed}
                        />,
                        container
                    );
                    crossroadInstance.resetState();
                }
            );
            route2.matched.add(() => {
                if (printRoutes) {
                    console.log('#2 - detail?ip={ip}&epoch={epoch}&probe={probe}&graphType={type}');
                }
            });

            // --------------------------------------- ROUTE 3 ------------------------------------------------
            let route3 = crossroadInstance.addRoute(
                'detail?ip={ip}&epoch={epoch}',
                (ip, epoch) => {
                    const end = Utils.getTimeNow();
                    const info = { endPoint: end, epoch, graphType: 'basic' };
                    ReactDOM.render(
                        <Main
                            ip={ip}
                            view='detail'
                            graph={info}
                            isMobile={Route.isMobile}
                            user={user}
                            username={username}
                            isLogged={auth}
                            IPAddAllowed={IPAddAllowed}
                        />,
                        container
                    );
                    crossroadInstance.resetState();
                }
            );
            route3.matched.add(() => {
                if (printRoutes) {
                    console.log('#3 - detail?ip={ip}&epoch={epoch}');
                }
            });

            // --------------------------------------- ROUTE 4 ------------------------------------------------
            let route4 = crossroadInstance.addRoute(
                'settings',
                () => {
                    ReactDOM.render(
                        <Main
                            view='settings'
                            isMobile={Route.isMobile}
                            user={user}
                            username={username}
                            isLogged={auth}
                            IPAddAllowed={IPAddAllowed}
                        />,
                        container
                    );
                    crossroadInstance.resetState();
                }
            );
            route4.matched.add(() => {
                if (printRoutes) {
                    console.log('#4 - settings');
                }
            });

            // --------------------------------------- Deafault ------------------------------------------------
            let routeDefault = crossroadInstance.addRoute(
                '',
                () => {
                    ReactDOM.render(
                        <Main
                            view="detail"
                            isMobile={Route.isMobile}
                            user={user}
                            username={username}
                            isLogged={auth}
                            IPAddAllowed={IPAddAllowed}
                        />,
                        container
                    );
                    crossroadInstance.resetState();
                }
            );
            routeDefault.matched.add(() => {
                if (printRoutes) {
                    console.log('Route default');
                }
            });

            crossroadInstance.bypassed.add((request) => {
                if (printRoutes) { console.log('Bypassed route'); }
                ReactDOM.render(<Error />, container);
            });

            crossroadInstance.routed.add(() => { if (printRoutes) { console.log('Rout routed'); } })
            const parseHash = (newHash, oldHash) => { crossroadInstance.parse(newHash); }

            hasher.initialized.add(parseHash); // parse initial hash
            hasher.changed.add(parseHash); // parse hash changes
            hasher.init(); // start listening for history change
        }
    },
};

export default Route;
