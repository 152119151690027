import React from 'react';
import GraphHeader from './header.jsx';
import GraphHeaderMobile from './headerMobile.jsx';

/**
 * probesList []
 * ipDetails []
 * selectedIP []
 * setActiveProbe []
 * handleDetailSwitch []
 * colorList []
 * isMobile [bool]
 * isNarrow [bool]
 * setEpochWithEndpoint [func]
 */

const HeaderWrap = (props) => {
    const sharedProps = {};
    sharedProps.probesList = props.probesList;
    sharedProps.ipDetails = props.ipDetails;
    sharedProps.selectedIP = props.selectedIP;
    sharedProps.setActiveProbe = props.setActiveProbe;
    sharedProps.handleDetailSwitch = props.handleDetailSwitch;
    sharedProps.colorList = props.colorList;
    sharedProps.isMobile = props.isMobile;
    sharedProps.isNarrow = props.isNarrow;
    sharedProps.setEpochWithEndpoint = props.setEpochWithEndpoint;
    if(props.isMobile){
        return (<GraphHeaderMobile {...props}/>)
    }
    return (<GraphHeader {...sharedProps} />);
}

export default HeaderWrap;
