import React from 'react';
import GraphProbe from '../probes.jsx';
import EpochPicker from '../epochPicker.jsx';
import Matrix from '../../matrix.jsx';

const GraphHeader = (props) => {
    const probesList = props.probesList;
    const probeName = props.ipDetails.probe.split('=')[1];
    return (
        <div className='header'>
            <GraphProbe
                activeProbe={props.ipDetails.probe}
                selectedIP={props.selectedIP}
                setActiveProbe={props.setActiveProbe}
                probesList={probesList}
                handleDetailSwitch={props.handleDetailSwitch}
                colorList={props.colorList}
                isMobile={props.isMobile}
                isNarrow={props.isNarrow}
            />
            <div className='epoch-matrix-block'>
                <EpochPicker
                    epoch={props.ipDetails.epoch}
                    setEpochWithEndpoint={props.setEpochWithEndpoint}
                    isMobile={props.isMobile}
                />
                <Matrix
                    ip={props.selectedIP}
                    activeProbe={probeName}
                    setActiveProbe={props.setActiveProbe}
                />
            </div>
        </div>
    );
}

export default GraphHeader;
