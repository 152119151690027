/* global $:true */
import React from 'react';
import Utils from '../../js/utils.js';

class SettingsGlobal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: this.props.data.public_url,
            prompt: {}
        };
    }

    inputChange = (e, key) => { this.setState({ [key]: e.target.value }); }

    urlChange = () => {
        const callbacks = {};
        const succMsg = 'Public url change was successful';
        const errMsg =  'Sorry, public url change was not successful';
        callbacks.successCallback = (succ) => { this.setState({ prompt: { isSuccess: true, msg: succMsg } }); };
        callbacks.errorCallback   = (err)  => { this.setState({ prompt: { isSuccess: false, msg: errMsg } }); };
        this.props.setUrl(this.state.url, callbacks);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data != nextProps.data) {
            this.setState({
                url: nextProps.data.public_url,
            });
        }
    }

    render() {
        return (
            <div className='tab-content'>
                {Utils.isSet(this.state.prompt) &&
                <div className={'banner-wrap__banner banner-wrap__banner--' + (this.state.prompt.isSuccess ? 'success' : 'error')}>
                    <div className='banner-wrap__text-block'>
                        <span>{this.state.prompt.msg}</span>
                    </div>
                    <button type='button' onClick={() => this.setState({ prompt: {} })} />
                </div>
                }
                <span className='page-title'>
                    {' '}
                    {this.props.name}
                    {' '}
                </span>
                <div className='form-content-wrap'>
                    <div className='input-wrap'>
                        <label className='label-title'>
                            <span>PUBLIC SHARE URL:</span>
                        </label>
                        <input
                            type='text'
                            value={this.state.url}
                            onChange={e => this.inputChange(e, 'url')}
                            className='long-input'
                        />
                    </div>
                </div>
                <div className='butts'>
                    <button type='submit' className='butt-confirm' onClick={this.urlChange}>Change URL</button>
                </div>
            </div>
        );
    }
}

export default SettingsGlobal;
