import React from 'react';
import Select2 from 'react-select2-wrapper';
import { withTooltip } from 'react-tippy';
import SVGInline from 'react-svg-inline';
import Utils from '../../js/utils.js';
import ModalWrapper from './modalWrapper.jsx';
import iconInfo from '../../../less/libs/icons/icon-info.svg';

/**
 * PROPS
 * data - [obj] - notify list, list of created alerts
 * templateLibrary - [obj] - list of template_alerts with existing alerts
 * alias - [string] - alert's name
 * isEdit - [bool] - is opened modal editing existing alert or creating a new one
 * updateAlerts - [func] - api call to update notify list
 * loadSmallTrigger - [bool] - loading different/small trigger, used inside the table to edit alerts
 *
 */
class AddAlertModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    openModal = () => this.setState({ ...this.getInitialState() })

    getEmptyTemplate = () => ({
        alias: '',
        method: 'POST',
        url: '',
        payload: '',
        contentType: 'application/json',
        headers: '{}',
        frequency: 0,
        frequencyPerIP: 0,
        areHeadersValid: true,
    })

    getInitialState = () => {
        let obj = {
            ...this.getEmptyTemplate(),
            template: '---',
            templateList: this.getTemplates()
        };
        const data = { ...this.props.data };
        data.headers = (Utils.isSet(data.headers)) ? JSON.stringify(data.headers) : '{}';
        const alias = Utils.isSet(this.props.alias) ? this.props.alias : '';
        this.validateState(data);
        return { ...Object.assign({}, obj, { ...data, alias }) };
    }

    getDropdownSelection = (dataOptions, stateKey, title, onSelect) => (
        <div className='select-wrap architecture-dropdown'>
            <span className='label-title'>{title}<i className="fa fa-star" /></span>
            <Select2
                className='select-box'
                data={dataOptions}
                onSelect={e => Utils.isSet(onSelect) ? onSelect(e.target.value) : this.setState({ [stateKey]: e.target.value })}
                value={this.state[stateKey]}
                options={{ minimumResultsForSearch: -1 }}
            />
        </div>
    );

    getTemplates = () => {
        const genericTemplates = this.props.templateLibrary;
        const allKeys = [...Object.keys(genericTemplates)];
        const dataOptions = allKeys.map(key => ({ text: key.toUpperCase(), id: key.toUpperCase() }));
        dataOptions.push({ text: '---', id: '---' });
        return dataOptions;
    }

    validateState = (newState) => {
        const methodSelection = ['POST','PUT'];
        const contentSelection = ['application/json', 'application/xml', 'text/plain'];
        if (!methodSelection.includes(newState.method)) { newState.method = methodSelection[0]; }
        if (!contentSelection.includes(newState.contentType)) { newState.contentType = contentSelection[0]; }
        if (Utils.isSet(newState.payload) && newState.payload.includes('<*DOMAIN*>')) {
            newState.payload = newState.payload.replace('<*DOMAIN*>', window.location.origin);
        };
        if (Utils.isSet(newState.headers) && typeof newState.headers === 'object') {
            newState.headers = JSON.stringify(newState.headers);
        };
    }

    loadFromTemplate = (key) => {
        const tempKey = key;
        this.setState((prevState) => {
            let template = { ...this.getEmptyTemplate() };
            if (tempKey !== '---') {
                template = { ...this.props.templateLibrary[tempKey] }; // in-case of hard-code-configed wrong template
                this.validateState(template);
            }
            return { ...Object.assign({}, prevState, template), template: tempKey };
        });
    }

    saveChanges = (e, modalCallback) => {
        e.preventDefault();
        let dataStructure = { ...this.state };
        dataStructure.headers = JSON.parse(dataStructure.headers);
        const keysToDelete = ['template', 'templateList', 'alias', 'helper', 'areHeadersValid'];
        keysToDelete.forEach(key => delete dataStructure[key]);
        let alias = this.state.alias;
        const isEdit = this.props.isEdit;
        let aliasToDelete = '';
        if (isEdit && this.state.alias !== this.props.alias) {
            aliasToDelete = this.props.alias;
        }
        this.props.updateAlerts(alias, dataStructure, isEdit, aliasToDelete);
        modalCallback({ verdict: true });
    }

    setHeaders = (headers) => {
        this.setState({
            headers,
            areHeadersValid: Utils.validateJsonString(headers),
            });
    }

    validateForm = () => {
        const isSet = Utils.isSet;
        const { alias, payload, frequency, frequencyPerIP } = this.state;
        const isValid = isSet(alias) && isSet(payload) && frequency > 0 && frequencyPerIP > 0;
        return !isValid; // isValid == !disabled
    }

    render() {
        const InfoIcon = () => (<SVGInline svg={iconInfo} />);
        const tooltipOptions = { style: { marginLeft: '10px' }, theme: 'dark', position: 'right', animation: 'perspective' };

        tooltipOptions.title = 'Content must be in a valid JSON format --> {"key":"value"} or empty {}';
        const HeadersTooltip = withTooltip(InfoIcon, { ...tooltipOptions });
        // --- next tooltip
        tooltipOptions.title = 'Minimum time interval in seconds between two notifications.';
        const FrequencyTooltip = withTooltip(InfoIcon, { ...tooltipOptions });
        // --- next tooltip
        tooltipOptions.title = 'Minimum time interval in seconds between two notifications for them same IP.';
        const FrequencyIPTooltip = withTooltip(InfoIcon, { ...tooltipOptions });
        // --- next tooltip
        tooltipOptions.title = 'Construct your payload by using the following values.' +
                                '<*IP*>, <*SLAVE*>, <*LATENCY*>, <*LOSS*>   ';
        const PayloadTooltip = withTooltip(InfoIcon, { ...tooltipOptions });
        const Trigger = ({ action }) => (
            <a onClick={action} className='add-alert'>
                <i className='fa fa-plus' />
                Add Alert
            </a>
        );
        const SmallTrigger = ({ action }) => (<i role="button" className='fa fa-pencil' onClick={action} />);
        const styles = { content: { height: 'auto' } };
        const data = this.state;
        const templateDropSelection = this.getDropdownSelection(
            data.templateList,
            'template',
            'Load template',
            this.loadFromTemplate
        );
        const methodDropSelection = this.getDropdownSelection(
            [{ text: 'POST', id: 'POST' },{ text: 'PUT', id: 'PUT' }],
            'method',
            'Select method',
        );
        const contentDropSelection = this.getDropdownSelection(
            [{ text: 'application/json', id: 'application/json' },
             { text: 'application/xml', id: 'application/xml' },
             { text: 'text/plain', id: 'text/plain' }],
            'contentType',
            'Select content-type',
        );
        const renderComponent = (confirmCallback) => (
            <form onSubmit={e => this.saveChanges(e, confirmCallback)}>
                <h2> { Utils.isSet(this.props.data) ? ('Edit ' + this.props.alias + ' alert') : 'Add alert'} </h2>
                <div className='alert-form-content'>
                    {templateDropSelection}
                    <div className='input-wrap'>
                        <label className='label-title'>
                            <span>ALIAS<i className="fa fa-star"/></span>
                        </label>
                        <input
                            type='text'
                            value={data.alias}
                            onChange={e => this.setState({ alias: e.target.value.toUpperCase() })}
                            className='short-input'
                            required
                        />
                    </div>
                    <div className="select-box">
                        {methodDropSelection}
                        {contentDropSelection}
                    </div>
                    <div className='input-wrap'>
                        <label className='label-title'>
                            <span>URL <i className="fa fa-star" /></span>
                        </label>
                        <input
                            type='text'
                            value={data.url}
                            onChange={e => this.setState({ url: e.target.value })}
                            className='long-input'
                            required
                        />
                    </div>
                    <div className='textarea-wrap textarea-wrap--small'>
                        <label className='label-title'>
                            <span>
                                Headers
                                <HeadersTooltip />
                                {!this.state.areHeadersValid &&
                                    <span className='red'> Invalid JSON</span>
                                }
                            </span>
                        </label>
                        <textarea
                            type='text'
                            value={data.headers}
                            onChange={e => this.setHeaders(e.target.value)}
                        />
                    </div>
                    <div className='textarea-wrap'>
                        <label className='label-title'>
                            <span>Payload <i className="fa fa-star" /> <PayloadTooltip /> </span>
                        </label>
                        <textarea
                            type='text'
                            placeholder="{ key:value}, JSON format"
                            value={data.payload}
                            onChange={e => this.setState({ payload: e.target.value })}
                        />
                    </div>
                    <div className="input-box">
                        <div className='input-wrap input-wrap__short'>
                            <label className='label-title'>
                                <span>
                                    Frequency
                                    <i className="fa fa-star" />
                                    <FrequencyTooltip />
                                </span>
                            </label>
                            <input
                                type='number'
                                min={0}
                                value={data.frequency}
                                onChange={e => this.setState({ frequency: Number(e.target.value) })}
                                className='short-input'
                                required
                            />
                        </div>
                        <div className='input-wrap input-wrap__short'>
                            <label className='label-title'>
                                <span>
                                    Frequency per IP
                                    <i className="fa fa-star" />
                                    <FrequencyIPTooltip />
                                </span>
                            </label>
                            <input
                                type='number'
                                min={0}
                                max={600}
                                value={data.frequencyPerIP}
                                onChange={e => this.setState({ frequencyPerIP: Number(e.target.value) })}
                                className='short-input'
                                required
                            />
                        </div>
                    </div>
                    <div className='butts mt-20'>
                        <button type='submit' className='modal-confirm' disabled={this.validateForm()}>
                            Save Changes
                        </button>
                    </div>
                </div>
            </form>
        );
        return (
            <ModalWrapper
                Trigger={this.props.loadSmallTrigger ? SmallTrigger : Trigger}
                style={styles}
                modalID='modal-add-alert'
                openModal={this.openModal}
                render={renderComponent}
                showRequiredInfo={true}
            />
        );
    }
}

export default AddAlertModal;
