/* global Chart:true, $:true, isNaN:true */

import hasher from 'hasher';

const Utils = {
    logUrl: false,
    defColor: '#486F97',
    apiVersion: 'v1',

    // ------------------------------ common functions -----------------------------------

    handleLogout() {
        document.cookie = 'cocoAuth =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
        document.cookie = '_ga =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
        document.cookie = '_gid =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
        document.cookie = '_gat =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
        window.location = '/';
    },

    setHashSilently(hash) {
        hasher.changed.active = false; // disable changed signal
        hasher.setHash(hash); // set hash without dispatching changed signal
        hasher.changed.active = true; // re-enable signal
    },

    getTimeNow() {
        return Math.round(new Date().getTime() / 1000);
    },

    getPropertyOfObject(path, original, end=undefined) {
        let obj = {...original};
        // safely obtain deep property in object by using reducer
        // const reducer = (accumulator, currentValue) => accumulator + currentValue;
        const reducer = (xs, x) => {
            // console.log('%c -- accumlator ', 'color: orange');
            // console.log(xs);
            // console.log('%c -- currentValue ', 'color: red');
            // console.log(x);
            return (xs && xs[x]) ? xs[x] : end ;
        };
        return path.reduce(reducer, obj);
    },

    getLossColor(lossColor) {
        if (lossColor == undefined) {
            return Utils.defColor;
        }
        const loss = Number(lossColor);
        if (loss == -1) {
            return '#FF5733';
        }
        let colorIndex = 0;
        if (loss.toFixed(1) > 0.1) {
            colorIndex = Math.ceil(loss / 10);
            colorIndex = colorIndex > 6 ? 6 : colorIndex;
        }
        return Chart.colorField[colorIndex];
    },

    sortKeys(data) {
        if (this.isSet(data)) {
            return Object.keys(data).sort(
                (a, b) => -(a.toString().toLowerCase() < b.toString().toLowerCase())
                        || +(a.toString().toLowerCase() != b.toString().toLowerCase()),
            );
        }
    },

    reSortValuesByKey(data, keys) {
        const arr = [];
        keys.forEach(key => arr.push(data[key]));
        return arr;
    },

    formatTime(ts, includeData=true) {
        const timestamp = this.convertTimeToJS(ts);
        const d = new Date(timestamp);
        return this.formatTimeToString(d, includeData, true);
    },

    formatTimeToString(d, includeDate = true, includeSeconds = false) {
        let out = '';
        if (includeDate) {
            out = `${d.getDate()}.${this.getMonthName(d.getMonth(), true)}`;
        }
        out += `   ${d.getHours()}:${d.getMinutes() < 10 ? '0' : ''}${d.getMinutes()}`;
        if (includeSeconds) {
            out += `:${d.getSeconds() < 10 ? '0' : ''}${d.getSeconds()}`;
        }
        return out;
    },

    getTimeFormatedInterval(interval) {
        const from = new Date(this.convertTimeToJS(interval.from));
        const to = new Date(this.convertTimeToJS(interval.to));
        let date = `${this.formatTimeToString(from)} - `;
        date += this.formatTimeToString(to, from.getDate() != to.getDate());
        return date;
    },

    convertTimeToJS(time) {
        if (time.toString().length < 13) {
            return time * 1000;
        }
        return time;
    },

    areObjectsSame(first, second) {
        return JSON.stringify(first) == JSON.stringify(second);
    },

    getContentWidth(narrow = false) {
        const width = Number(window.innerWidth);
        if (!narrow) {
            return width;
        }
        const narrowConstant = 0.8;
        const minSidePanelWidth = 250; // mint width for side-panel that is 20%
        const maxSidePanelWidth = 400; // mint width for side-panel that is 20%
        let baseWidth = width * narrowConstant;
        baseWidth = ((width * 0.2) < minSidePanelWidth) ? width - minSidePanelWidth : baseWidth;
        baseWidth = ((width * 0.2) > maxSidePanelWidth) ? width - maxSidePanelWidth : baseWidth;
        return baseWidth;
    },

    getChartLineWidth(isMob = false, narrow = false) {
        if (isMob) {
            return Math.round(window.innerWidth - 54);
        }
        const basewidth = this.getContentWidth(narrow)
        return Math.round(((basewidth * 0.95) * 0.95) - 75);
    },

    getProbeWidth(isMob = false, narrow = false) {
        if (isMob) {
            return Math.round(window.innerWidth - 54);
        }
        const basewidth = this.getContentWidth(narrow)
        return Math.round((basewidth * 0.95) - 30);
    },

    getModalWidth() {
        const width = Math.round(window.innerWidth * 0.60);
        return width > 1500 ? 1500 : width;
    },

    formatNumber(n, floatPart = 2, delimeter = ' ') {
        const num = Number(n);
        const tofixed = num.toFixed(floatPart);
        return tofixed.replace(/./g, (c, i, a) => (i && c !== '.' && ((a.length - i) % 3 === 0) ? delimeter + c : c));
    },

    getMonthName(index, shortVersion = true) {
        const month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
            'October', 'November', 'December'];
        const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return shortVersion ? month_names_short[index] : month_names[index];
    },


    validateJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },

    isSet(value) {
        if (value == null) {
            return false;
        }
        const val = typeof value;
        switch (val) {
        case 'object':
        case 'string':
            return Object.keys(value).length > 0;
        case 'undefined':
            return false;
        default:
            return true;
        }
    },

    isNotSet(value) {
        return !this.isSet(value);
    },

    getIpRegex() {
        // eslint-disable-next-line
        const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])( *)$/;
        return ipRegex;
    },

    getSubnetRegex() {
        // eslint-disable-next-line
        const subnetRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/([1-9]|[1-2][0-9]|3[0-2])( *)$/;
        return subnetRegex;
    },

    getIPandSlave(data) {
        const obj = { ip: data, slave: '' };
        if (data.includes('@')) {
            const field = data.split('@');
            obj.ip = field[0];
            obj.slave += field[1];
        }
        return obj;
    },

    copyEffect(selector) {
        selector.css('color', '#19e880');
        setTimeout(() => {
            selector.css('color', '#486F97');
        }, 2000);
    },

    ip2long(ip) {
        let components;
        if (components = ip.match(/^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/)) {
            let iplong = 0;
            let power = 1;
            for (let i = 4; i >= 1; i -= 1) {
                iplong += power * parseInt(components[i]);
                power *= 256;
            }
            return iplong;
        }
        return -1;
    },

    inSubNet(ip, subnet) {
        let mask; let base_ip; const
            long_ip = this.ip2long(ip);
        if ((mask = subnet.match(/^(.*?)\/(\d{1,2})$/)) && ((base_ip = this.ip2long(mask[1])) >= 0)) {
            const freedom = Math.pow(2, 32 - parseInt(mask[2]));
            return (long_ip > base_ip) && ((long_ip < base_ip + freedom - 1) || (long_ip === base_ip + freedom - 1));
        } return false;
    },


    // -------------------------------------- API CALL WRAPPER ---------------------------------------------------------

    setLogUserRef(ref) {
        this.logUser = ref;
    },

    formApiData(url, async, dataType, successCallback, errorCallback) {
        if (!url.startsWith('/')) {
            url = `/${url}`;
        }
        return {
            url,
            async,
            dataType,
            successCallback,
            errorCallback,
        };
    },

    performGETcall(call, type = 'GET', loguser = true) {
        if (Utils.logUrl) {
            console.log(call.url);
        }
        $.ajax({
            url: `/${Utils.apiVersion}${call.url}`,
            type,
            async: call.async,
            dataType: call.dataType,
            success(data) {
                const error = data.result === 'error';
                const isNotLogged =  error && (data.error === 'auth required' || data.error === 'admin auth required');
                if (isNotLogged && loguser) { Utils.logUser(false); }
                // ERROR CALLBACK
                if (error && Utils.isSet(call.errorCallback)) { call.errorCallback(data); }
                // SUCCESS CALLBACK
                if (!error && Utils.isSet(call.successCallback)) { call.successCallback(data); }
            },
            error(data) {
                // ERROR CALLBACK
                if (Utils.isSet(call.errorCallback)) { call.errorCallback(data); }
            },
        });
    },

    performPUTcall(call, type = 'PUT', loguser = true) {
        if (Utils.logUrl) {
            console.log(call.url);
        }
        $.ajax({
            url: `/${Utils.apiVersion}${call.url}`,
            type,
            async: call.async,
            dataType: call.dataType,
            contentType: call.contentType,
            data: JSON.stringify(call.data),
            success(data) {
                const error = data.result === 'error';
                const isNotLogged =  error && (data.error === 'auth required' || data.error === 'admin auth required');
                if (isNotLogged && loguser) { Utils.logUser(false); }
                // ERROR CALLBACK
                if (error && Utils.isSet(call.errorCallback)) { call.errorCallback(data); }
                // SUCCESS CALLBACK
                if (!error && Utils.isSet(call.successCallback)) { call.successCallback(data); }
            },
            error(data) {
                // ERROR CALLBACK
                if (Utils.isSet(call.errorCallback)) { call.errorCallback(data); }
            },
        });
    },

    setConfigIp(calldata, ip, callback) {
    // ----------------- API CALL PUT -----------------

        const successCallback = (succ) => { callback({ verdict: true }); };
        const errorCallback = (err) => { callback({ verdict: false, errorPrompt: err.error }); };
        const data = Utils.formApiData(`/config/ping/${ip}`, true, 'json', successCallback, errorCallback);
        data.contentType = 'application/json';
        data.data = calldata;
        Utils.performPUTcall(data);
    },

    deleteIp(ip, callback, type = 'ping') {
    // ----------------- API CALL DELETE -----------------
        const successCallback = (succ) => { callback({ verdict: true }); };
        const errorCallback = (err) => { callback({ verdict: false, errorPrompt: err.error }); };
        const data = Utils.formApiData(`/config/${type}/${ip}`, true, 'json', successCallback, errorCallback);
        Utils.performGETcall(data, 'DELETE');
    },
};
// ------------------------------------------------------------------------------------------------------------

Utils.modals = {
    config: {
        overlay: {
            position: 'fixed',
            overflow: 'hidden',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
            background: 'rgba(43, 46, 56, 0.7)',
        },
        content: {
            boxSizing: 'border-box',
            padding: '30px 0',
            color: 'black',
            background: 'white',
            border: 'none',
            textAlign: 'left',
            transition: 'background 0.3s, color 0.3s',
            width: '60%',
            maxWidth: '2000px',
            minHeight: '300px',
            maxHeight: '80%',
            borderRadius: '10px',
            margin: '20px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
        },
    },

    onIpChange(inputSelector) {
        const errorMessage = 'Please, fill out a valid IP Address';
        const regex = Utils.getIpRegex();
        const scope = this;
        const inputValue = inputSelector.val();
        const form = inputSelector.parent('form');
        inputSelector.attr('title', errorMessage);
        if (regex.test(inputValue)) {
            inputSelector.removeClass('input-red');
            inputSelector.addClass('input-green');
            $('.error-msg-form').remove();
            scope.enableSubmitting(form);
        } else {
            inputSelector.removeClass('input-green');
            inputSelector.addClass('input-red');
            if (!inputSelector.next('.error-msg-form').length) {
                const errorEl = `<div class='error-msg-form'>${errorMessage}</div>`;
                inputSelector.after(errorEl);
            }
            scope.disableSubmitting(form);
        }
    },

    enableSubmitting(form) {
        const submitButt = form.find('[type="submit"]');
        submitButt.prop('disabled', false);
        submitButt.removeClass('submit-butt-disabled');
        form.on('keydown', (e) => {
            if (e.keyCode == 13) {
                form.submit();
            }
        });
    },

    disableSubmitting(form) {
        const submitButt = form.find('[type="submit"]');
        submitButt.prop('disabled', true);
        submitButt.addClass('submit-butt-disabled');
        form.on('keydown', (e) => {
            if (e.keyCode == 13) {
                e.preventDefault();
            }
        });
    },
};

export default Utils;
