import React from 'react';
import { withTooltip } from 'react-tippy';
import Utils from '../../js/utils.js';

/**
 * PROPS
 * pushNotify - [arr] - array of alert names that are active - (e.g.[slack,pager,email]
 * latencyThreshold - [num] - latency value threshold for a given group
 * lossThreshold - [num] - packet loss value threshold for a given group
 * isNewCategory - [bool] - boolean that is true when modal is opened to create a new autogroup
 */

class ModalWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifyOptions: {},
            alertSelection: {},
            selectAll: false,
        };
    }

    setAlertSelection = (key) => {
        let selectedAlerts = [];
        let outsideFunction = this.props.setStateByKey;
        this.setState((prevState) => {
            let selection = { ...prevState.alertSelection };
            selection[key] = !selection[key];
            selectedAlerts = Object.keys(selection).filter(key => (selection[key])); // filter all selected alerts, keys only
            outsideFunction('pushNotifyA', selectedAlerts);
            return ({ alertSelection: selection });
        });
    }

    loadData = () => {
        const url = '/notify';
        let notifyList = [];
        let { pushNotifyA } = this.props;
        const successCallback = (notifyOptions) => {
            notifyList = Object.keys(notifyOptions);
            let selection = {};
            notifyList.forEach((key) => { selection[key] = pushNotifyA.includes(key); });
            this.setState({ notifyOptions, alertSelection: selection });
        }
        const data = Utils.formApiData(url, true, 'json', successCallback);
        Utils.performGETcall(data);
    }


    getNotifySelector = () => {
        const options = Object.keys(this.state.notifyOptions);
        if (options.length <= 0) {
            return (<span> No notification group available. ( Go to general settings - alerts)</span>);
        }
        if (Utils.isSet(this.state.alertSelection)) {
            let checkboxList = options.map(val => (
                <li className='alert-option' key={val} onClick={e => this.setAlertSelection(val)}>
                    <label className='inp-check inp-check-no-event'>
                        <input
                            type='checkbox'
                            name='alert-option'
                            id={val}
                            checked={this.state.alertSelection[val]}
                            onChange={() => {}}
                        />
                        <span>{val}</span>
                    </label>
                </li>
            ));
            return (
                <ul className='alert-option-list row'>
                    <li className='alert-option' onClick={e => this.selectAll()}>
                        <label className='inp-check inp-check-no-event'>
                            <input
                                type='checkbox'
                                name='alert-option'
                                checked={this.state.selectAll}
                                onChange={() => {}}
                            />
                            <span>* ALL *</span>
                        </label>
                    </li>
                    {checkboxList}
                </ul>
            );
        }
        return (
            <span> initializing ... </span>
        );
    }

    selectAll = () => {
        let outsideFunction = this.props.setStateByKey;
        this.setState((prevState) => {
            let bool = !prevState.selectAll;
            let selection = { ...prevState.alertSelection };
            let notifyList = Object.keys(selection);
            notifyList.forEach((key) => { selection[key] = bool; });
            outsideFunction('pushNotifyA', (bool) ? notifyList : []);
            return ({ selectAll: bool, alertSelection: selection });
        });
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const InfoIcon = () => (<i className='fa fa-info-circle' />);
        const tooltipOptions = {
            style: { marginLeft: '20px' },
            theme: 'dark',
            position: 'right',
            animation: 'perspective',
        };
        const alertTool = { ...tooltipOptions };
        alertTool.title = 'Select alert options to turn on alerting. ' +
            'New alert services can admins add in general settings under alerts section.';
        const duration = {...tooltipOptions};
        duration.title = 'Alert is triggered only when thresholds are over-stepped continuously for X minutes. In other' +
            'words, alert is triggered if (X-1) minute blocks before also over-stepped the threshold.';
        const AlertTooltip = withTooltip(InfoIcon, alertTool);
        const DurationTooltip = withTooltip(InfoIcon, duration);
        return (
            <div className="threshold">
                <p>
                    Set your threshold values and select desired alert options.
                    <br />
                    Thresholds independent and checked for every IP in the group once a minute.
                    <br />
                    If measured values get bigger, than the threshold a notification is sent via all selected alert services.
                    <br />
                    Value 0 turns the threshold control off.
                </p>
                <div className="threshold-wrap">
                    <div className="threshold-input">
                        <span>LATENCY (ms)</span>
                        <input
                            type='number'
                            min={0}
                            max={10000}
                            value={this.props.latencyThreshold}
                            onChange={(e) => {
                                let val = Number(e.target.value);
                                val = (val > 10000) ? 10000 : val;
                                val = (val < 0) ? 1 : val;
                                this.props.setStateByKey('latencyThreshold', Number(val));
                            }}
                        />
                    </div>
                    <div className="threshold-input">
                        <span>PACKET LOSS (%)</span>
                        <input
                            type='number'
                            min={0}
                            max={100}
                            value={this.props.lossThreshold}
                            onChange={(e) => {
                                let val = Number(e.target.value);
                                val = (val > 100) ? 100 : val;
                                val = (val < 0) ? 1 : val;
                                this.props.setStateByKey('lossThreshold', Number(val));
                            }}
                        />
                    </div>
                    <div className="threshold-input">
                        <span>DURATION (min) <DurationTooltip/></span>
                        <input
                            type='number'
                            min={0}
                            max={1500}
                            value={this.props.timeThreshold}
                            onChange={(e) => {
                                let val = Number(e.target.value);
                                val = (val > 1500) ? 1500 : val;
                                val = (val < 0) ? 1 : val;
                                this.props.setStateByKey('timeThreshold', Number(val));
                            }}
                        />
                    </div>
                </div>
                <div>
                    <span>Alert options <AlertTooltip/> </span>
                    {this.getNotifySelector()}
                </div>
            </div>
        );
    }
}

export default ModalWrapper;